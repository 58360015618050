/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertToCsv = (arr: any) => {
  const keys = Object.keys(arr[0]);
  const replacer = (_key: any, value: any) => (value === null ? '' : value);
  const processRow = (row: any) => keys.map((key) => JSON.stringify(row[key], replacer)).join(',');
  return [keys.join(','), ...arr.map(processRow)].join('\r\n');
};

export const downloadCsv = (fileName: string, data: any) => {
  const link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
  link.setAttribute('download', fileName);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
