import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Prism from 'prismjs';
import FeatherIcon from 'feather-icons-react';

import { useClipboard } from '../../../hooks';

import 'prismjs/themes/prism-tomorrow.css';

interface IProps {
  code: string;
  language?: string;
}
export const HighlightedCode: FC<IProps> = ({ code, language = 'markup' }) => {
  const { t } = useTranslation();
  const { copied, copy } = useClipboard();

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const copyHandler = () => {
    copy(code);
  };

  return (
    <div className="position-relative">
      <pre>
        <code className={`language-${language}`}>{code.trim()}</code>
      </pre>

      <button type="button" className="btn btn-sm btn-info position-absolute top-0 end-0" onClick={copyHandler}>
        <FeatherIcon icon="copy" size={20} className="mr-2" />
        {copied ? t('copied') : t('copy')}
      </button>
    </div>
  );
};
