import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../index.css';

type RegistrationAlertProps = {
  text: string;
  type?: string;
  banner?: boolean;
};

const RegistrationAlert: React.FC<RegistrationAlertProps> = ({ text, type, banner }) => {
  const { t } = useTranslation();

  const [hide, setHide] = useState(false);

  return type === 'otp' ? (
    banner ? (
      !hide ? (
        <div className="banner d-flex justify-content-between align-items-center">
          <p style={{ margin: 0 }}>{t('errors.general.120')}</p>
          <img
            style={{ height: 15, width: 15, marginTop: -2, cursor: 'pointer' }}
            src="/assets/images/auth/close.svg"
            onClick={() => setHide(true)}
          />
        </div>
      ) : null
    ) : (
      <div className="error-message m-t-5"> {t(text)}</div>
    )
  ) : type === 'note' ? (
    !hide ? (
      <div className="d-flex justify-content-between align-items-center" style={{ maxWidth: 300 }}>
        <div className="note">{t(text)}</div>
        <img
          style={{ height: 15, width: 15, marginTop: -2, cursor: 'pointer' }}
          src="/assets/images/auth/close.svg"
          onClick={() => setHide(true)}
        />
      </div>
    ) : null
  ) : (
    <div role="alert" className="error-message m-t-5">
      {t(text)}
    </div>
  );
};

export default RegistrationAlert;
