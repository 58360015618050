import { createContext } from 'react';

export interface IAuthContext {
  signUpId?: string | null;
  isAuthorized: boolean;
  onLogIn: (token: string, tokenDate: string) => void;
  onLogOut: () => void;
  onSignUpIdUpdated: (id: string) => void;
  token?: string | null | undefined;
}

export const AuthContext = createContext<IAuthContext>({
  isAuthorized: false,
  token: '',
  signUpId: '',
  onLogIn: () => {
    //
  },
  onLogOut: () => {
    //
  },
  onSignUpIdUpdated: () => {
    //
  },
});
