import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isImageUrl } from '../../../lib/isImageUrl';
import { createThumbnailFromImgUrl, ICreateThumbnailFromImgUrlReturn } from '../../../lib/createThumbnailFromImgUrl';
import { createThumbnailFromUrl, ICreateThumbnailFromUrlReturn } from '../../../lib/createThumbnailFromUrl';
import { FormElementError } from '../FormElementError/FormElementError';

interface IProps {
  className?: string;
  onSuccess?: (thumb: ICreateThumbnailFromImgUrlReturn | ICreateThumbnailFromUrlReturn) => void;
}

export const UrlUploader: FC<IProps> = (props) => {
  const { className, onSuccess } = props;
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const addThumbnailHandler = () => {
    const url = new URL(inputRef.current!.value.trim());

    if (!url) {
      return;
    }

    setErrorMsg(undefined);
    setIsLoading(true);

    if (isImageUrl(url)) {
      createThumbnailFromImgUrl(url)
        .then((result) => {
          console.log(result);
          onSuccess?.(result);
          inputRef.current!.value = '';
        })
        .catch((e) => {
          setErrorMsg(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createThumbnailFromUrl(url)
        .then((result) => {
          console.log(result);
          onSuccess?.(result);
          inputRef.current!.value = '';
        })
        .catch((e) => {
          setErrorMsg(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={className}>
      <div className={'d-flex' + (errorMsg ? ' is-invalid' : '')}>
        <input
          ref={inputRef}
          className={'form-control' + (errorMsg ? ' is-invalid' : '')}
          placeholder="https://example.com/image.jpg"
        />
        <button className="btn btn-secondary ml-2" onClick={addThumbnailHandler} type="button" disabled={isLoading}>
          {t('add')}
        </button>
      </div>
      {errorMsg ? <FormElementError text={errorMsg} /> : null}
    </div>
  );
};
