/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useTranslation } from 'react-i18next';

type ToastProps = {
  message: string;
  errorToast?: boolean;
};

const Toast: React.FC<ToastProps> = ({ message, errorToast }) => {
  const { t } = useTranslation();

  return (
    <div className="notifier-container" id="notifier-container">
      <div className={`notifier ${errorToast ? 'bg-light-danger' : 'success'}  shown`} id="notifier-0">
        <span className="notifier-title">{t(errorToast ? 'error' : 'success')}</span>
        <div className="notifier-body">{message}</div>
      </div>
    </div>
  );
};
export default Toast;
