import { mergician } from 'mergician';

export function getUnifiedFormData(formEl: HTMLFormElement) {
  return Array.from(new FormData(formEl).entries()).reduce((result: any, [key, value]) => {
    // isObject
    if (key.includes('.')) {
      const nestedKeys = key.match(/\b\w+\b(?=\.?)/g); // convert string some.nested.str ==> ['some', 'nested', 'str'];
      if (nestedKeys === null) return;

      const createNestedObject = (keys: string[], value: any): any => {
        if (keys.length === 0) {
          return value;
        }

        const key = keys.shift()!;
        const nestedValue = createNestedObject(keys, value);

        return { [key]: nestedValue };
      };

      const nestedObj = createNestedObject(nestedKeys, value);

      return mergician(result, nestedObj);
    }

    if (Object.hasOwn(result, key)) {
      if (Array.isArray(result[key])) {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
    return result;
  }, {});
}
