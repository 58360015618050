import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ref as firebaseRef } from 'firebase/storage';

import { isImage } from '../../../../lib/isImage';
import { getImageDimensions } from '../../../../lib/getImageDimensions';
import { FileUploader, FormElementError, UploadedFilePreview } from '../../../common';
import { storage } from '../../../../config/firebase';
import { uploadToFirebaseStorage } from '../../../../lib/firebase/uploadToFirebaseStorage';
import { deleteFromFirebaseStorage } from '../../../../lib/firebase/deleteFromFirebaseStorage';
import { createThumbObjectFromUrl } from '../../../../lib/createThumbObjectFromUrl';

interface IProps {
  organizationId: string;
  className?: string;
  formErrors?: any;
  initialValue?: string;
}
export const ImageUploader: FC<IProps> = (props) => {
  const { organizationId, className, initialValue, formErrors: errors } = props;
  const { t } = useTranslation();

  const thumbnailsRef = useRef<HTMLDivElement>(null);

  const [formErrors, setFormErrors] = useState<any>(errors);
  const [thumbnails, setThumbnails] = useState<any[]>(initialValue ? [createThumbObjectFromUrl(initialValue)] : []);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(initialValue ? [initialValue] : []);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

  const uploadFileHandler = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement)?.files;

    if (!files?.length) {
      return;
    }

    setFormErrors(undefined);

    setIsLoadingFile(true);

    await Array.fromAsync(files, async (f) => {
      try {
        await uploadFile(f);
        await createThumbnail(f);
        return f;
      } catch (error) {
        return;
      }
    });

    setIsLoadingFile(false);

    // const urls = await Promise.all(
    //   Array.from(files, async (f: File) => {
    //     const storageRef = firebaseRef(storage, `non-organization/${f.name}`);
    //     return await uploadToFirebaseStorage(storageRef, f);
    //   })
    // );

    async function uploadFile(file: File) {
      const validate = validateFile(file);
      if (!validate.success) {
        setFormErrors({
          photo_url: [validate.message],
        });
        throw new Error(validate.message);
      }

      const uploadedUrl = await uploadToFirebase(file);

      await deleteFile(0); // delete exist avatar from the firebase store before new uploaded

      setUploadedFiles([uploadedUrl]);
    }

    async function createThumbnail(file: File) {
      return new Promise((resolve, reject) => {
        const { name, size, type: fileType } = file;
        const reader = new FileReader();
        const validate = validateFile(file);
        if (!validate.success) {
          setFormErrors({
            photo_url: [validate.message],
          });
          reject(validate.message);
          return;
        }

        reader.onload = async (event) => {
          const result = event.target?.result as string;
          let width = null;
          let height = null;
          if (isImage(name)) {
            const dimensions = await getImageDimensions(result);
            width = dimensions.width;
            height = dimensions.height;
          }
          const thumbObj = {
            type: isImage(name) ? 'image' : 'file',
            name,
            size: Math.round(size / 1024),
            width,
            height,
            dataUrl: result,
          };

          setThumbnails([thumbObj]);

          resolve(result);
        };

        reader.readAsDataURL(file);
      });
    }
  };

  function validateFile(file: File): { success: boolean; message?: string } {
    const error = {
      success: false,
      message: '',
    };

    if (file.size / 1024 > +process.env.REACT_APP_AVATAR_SIZE_LIMIT!) {
      error.message = 'The file size limit is reached';
      return error;
    }

    return { success: true };
  }

  const uploadToFirebase = async (file: File): Promise<string> => {
    const storageRef = firebaseRef(storage, `${organizationId}/vcard/${Date.now()}-${file.name}`);
    return await uploadToFirebaseStorage(storageRef, file);
  };

  const deleteFileHandler = async (index: number): Promise<any> => {
    deleteFile(index);
    removeThumbnail(index);
  };

  const deleteFile = async (index: number) => {
    if (!thumbnails[index]?.originalUrl) {
      try {
        await deleteFromFirebaseStorage(firebaseRef(storage, uploadedFiles[index]));
      } catch (error) {
        console.error(error);
      }

      setUploadedFiles(uploadedFiles.toSpliced(index, 1));
    }
  };

  const removeThumbnail = (index: number) => {
    const blobUrl = thumbnails[index].blobUrl;
    blobUrl && URL.revokeObjectURL(blobUrl);

    setThumbnails(thumbnails.toSpliced(index, 1));
  };

  console.log('thumbnails', thumbnails);

  return (
    <div className={className}>
      <input
        className={'form-control' + (formErrors?.photo_url?.length ? ' is-invalid' : '')}
        type="hidden"
        name="photo_url"
        value={uploadedFiles[0] ?? ''}
      />
      <label className="form-label">{t('vcard.forms.photoUrl')}:</label>
      <FileUploader
        id="avatar-upload-btn"
        onUpload={(e) => uploadFileHandler(e)}
        isLoading={isLoadingFile}
        accept="image/*"
      />
      {/* <input
        type="text"
        className={'form-control' + (formErrors?.photo_url?.length ? ' is-invalid' : '')}
        name="photo_url"
        placeholder="https://example.com/logo.jpg"
        defaultValue={initialValue ?? ''}
      /> */}
      <small className="form-text text-muted d-block text-center">
        {t('maxFileSize', { size: +process.env.REACT_APP_AVATAR_SIZE_LIMIT! / 1024 })}
      </small>

      {formErrors?.photo_url?.length ? <FormElementError text={formErrors.photo_url[0] || ''} /> : null}

      {thumbnails.length > 0 ? (
        <div ref={thumbnailsRef} className="thumbnails d-flex flex-column gap-2 mt-3">
          {thumbnails.map((file, idx) => (
            <UploadedFilePreview key={idx} file={file} onDelete={() => deleteFileHandler(idx)} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
