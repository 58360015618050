import { FC, ReactNode, useState, MouseEvent } from 'react';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { Portal } from '../Portal/Portal';

import './Tooltip.css';

interface IProps {
  text: string | ReactNode;
  triggerEl: ReactNode;
  className?: string;
}

export const Tooltip: FC<IProps> = (props) => {
  const { text, triggerEl, className } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles } = useFloating({
    open: isOpen,
    placement: 'right',
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hoverHandler = (e: MouseEvent) => {
    setIsOpen(true);
  };
  const blurHandler = (e: MouseEvent) => {
    setIsOpen(false);
  };

  const rootClasses = ['lp-tooltip'];
  if (className) {
    rootClasses.push(className);
  }

  return (
    <div className={rootClasses.join(' ')}>
      <button
        ref={refs.setReference}
        onMouseEnter={hoverHandler}
        onMouseLeave={blurHandler}
        className="lp-tooltip__trigger-btn"
      >
        {triggerEl}
      </button>
      {isOpen ? (
        <Portal>
          <div ref={refs.setFloating} style={floatingStyles} className="lp-tooltip__body">
            {text}
          </div>
        </Portal>
      ) : null}
    </div>
  );
};
