import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { DateTime } from 'luxon';

import { ICampaign, TWhenSendMessages } from '../../types';
import { mapCampaign } from '../../lib/mappers';
import { prepareTestMessageData } from './lib/prepareTestMessageData';
import { useCollapsiblePanel } from '../../hooks';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import { campaignsAPIGetCreateCampaign, campaignsAPIPostCreateCampaign } from '../../api/campaigns';
import ErrorPopup from '../../components/errors/ErrorPopup';
import { CollapsiblePanel, WeekDaysCheckboxes } from '../../components/common';
import { prepareCampaignData } from './lib/prepareCampaignData';
import Spinner from './common/Spinner';
import { CampaignUploader } from './common/CampaignUploader/CampaignUploader';
import { SendTestMessage } from '../../components';

import 'react-datepicker/dist/react-datepicker.css';

const CreateCampaign: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const navigate = useNavigate();

  const {
    state: { organizationData },
  } = useLocation();

  const [campaign, setCampaign] = useState<ICampaign | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [whenSendMessages, setWhenSendMessages] = useState<TWhenSendMessages>('immediately');

  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    (async () => {
      if (!token || !organizationData.id) {
        console.error('You miss token or organizationId');
        return;
      }

      setIsLoading(true);

      try {
        if (token) {
          const res = await campaignsAPIGetCreateCampaign(token, organizationData.id);

          if (res.code) {
            setIsPopup(true);
            setErrorText(`${res.message}. Error code: ${res.code}`);
            return;
          }

          if ((!res.success && res.status && res.status !== 200) || !res) {
            setIsPopup(true);
            setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
            return;
          }

          const campaignData = mapCampaign(res);
          setCampaign(campaignData);
          setWhenSendMessages(campaignData?.fromDate ? 'schedule' : 'immediately');
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [token, organizationData.id]);

  useEffect(() => {
    setCampaign({ ...campaign!, fromDate: DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd') });
  }, [startDate]);

  const recipientsOptions = useMemo(() => {
    return campaign?.lists.length
      ? campaign.lists.map((recipient) => ({
          value: recipient.id,
          title: recipient.id === 'all' ? t(recipient.id) : recipient.name,
        }))
      : [];
  }, [campaign?.lists?.length, t]);

  const effectOptions = useMemo(() => {
    return campaign?.effects?.imessage.length
      ? campaign.effects.imessage.map((effect) => ({
          value: effect,
          title: t(effect),
        }))
      : [];
  }, [campaign?.effects?.imessage.length, t]);

  const sendFromTimeOptions = useMemo(() => {
    return campaign?.sendFromTime?.length
      ? campaign.sendFromTime.map((time) => ({
          value: time,
          title: time,
        }))
      : [];
  }, [campaign?.sendFromTime]);

  const sendToTimeOptions = useMemo(() => {
    return campaign?.sendToTime?.length
      ? campaign.sendToTime.map((time) => ({
          value: time,
          title: time,
        }))
      : [];
  }, [campaign?.sendToTime]);

  const timezoneOptions = useMemo(() => {
    return campaign?.timezones?.length
      ? campaign.timezones.map((timezone) => ({
          value: timezone.id,
          title: timezone.name,
        }))
      : [];
  }, [campaign?.timezones]);

  const whenSendMessagesOptions = useMemo<Array<{ value: TWhenSendMessages; title: string }>>(() => {
    return [
      { value: 'immediately', title: t('sendImmediately') },
      { value: 'schedule', title: t('scheduleForLater') },
    ];
  }, [t]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setCampaign({ ...campaign!, [name]: value });
  };

  const onAttachmentAdd = (urls: string[]) => {
    setCampaign({ ...campaign!, attachments: urls });
  };

  const whenSendMessagesHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setWhenSendMessages(value as TWhenSendMessages);
    setStartDate(campaign?.fromDate ? new Date(campaign?.fromDate) : new Date());
  };

  const submitHandler = (status: string) => {
    if (!campaign?.name || campaign?.name === '' || !campaign?.text || campaign?.text === '') {
      setIsPopup(true);
      setErrorText(t(`fillRequiredFields`));
      return;
    }

    (async () => {
      try {
        if (!token) {
          console.error('You missed token');
          return;
        }

        const preparedCampaignData = prepareCampaignData(campaign);
        const res = await campaignsAPIPostCreateCampaign(token, { ...preparedCampaignData, status });

        if (res.code) {
          setIsPopup(true);
          setErrorText(`${res.message}. Error code: ${res.code}`);
          return;
        }
        if ((!res.success && res.status && res.status !== 200) || !res) {
          setIsPopup(true);
          setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
          return;
        }

        const campaignId = res.id;
        navigate(`/campaign/${campaignId}`);
      } catch (e) {
        console.log(e);
      }
    })();
  };

  const popupClose = (c: boolean) => {
    setIsPopup(c);
  };

  const {
    handleClose: handleCloseUtmTags,
    handleOpen: handleOpenUtmTags,
    isAnimation: isUtmTagsAnimation,
    isOpen: isUtmTagsOpen,
  } = useCollapsiblePanel();

  const {
    handleClose: handleCloseCustomSendingOptions,
    handleOpen: handleOpenCustomSendingOptions,
    isAnimation: isCustomSendingOptionsAnimation,
    isOpen: isCustomSendingOptionsOpen,
  } = useCollapsiblePanel();

  const utmTagsCollapsePanelHandler = () => {
    isUtmTagsOpen ? handleCloseUtmTags() : handleOpenUtmTags();
  };
  const customSendingOptionsCollapsePanelHandler = () => {
    isCustomSendingOptionsOpen ? handleCloseCustomSendingOptions() : handleOpenCustomSendingOptions();
  };

  return (
    <div className="main-body">
      <div className="page-wrapper">
        <div className="page-header-title">
          <h3 className="m-b-10">{t('addCampaign')}</h3>
        </div>
        <div className="card">
          <div className="card-header">
            <h5>{t('campaignInformation')}</h5>
          </div>
          {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}
          {!isLoading ? (
            <>
              <div className="card-block">
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ paddingLeft: 0, borderTop: 'none' }}>{t('vcard.forms.organizationName')}</td>
                      <td style={{ paddingRight: 0, borderTop: 'none' }} className="text-right">
                        {organizationData?.name}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <label className="form-label" htmlFor="oranization-name">
                    {t('campaignName')}
                  </label>
                  <input
                    type="text"
                    id="oranization-name"
                    className="form-control"
                    name="name"
                    onChange={onInputChange}
                    value={campaign?.name ?? ''}
                    required
                  />
                  <small className="form-text text-muted"> {t('requiredField')}</small>
                </div>
              </div>
              <div className="card-header">
                <h5>{t('recipients')}</h5>
              </div>
              <div className="card-block">
                {recipientsOptions.length > 0 ? (
                  <select className={'form-select'} name="list" onChange={onInputChange} value={campaign?.list}>
                    {recipientsOptions.map((recipient) => (
                      <option key={recipient.value} value={recipient.value}>
                        {recipient.title}
                      </option>
                    ))}
                  </select>
                ) : null}
              </div>
              <div className="card-header">
                <h5>{t('content')}</h5>
              </div>
              <div className="card-block">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="campaign-subject">
                        {t('subject')}
                      </label>
                      <input
                        type="text"
                        id="campaign-subject"
                        className="form-control"
                        name="subject"
                        placeholder={t('subject')}
                        onChange={onInputChange}
                        value={campaign?.subject ?? ''}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="campaign-text">
                        {t('text')}
                      </label>
                      <textarea
                        id="campaign-text"
                        className="form-control"
                        name="text"
                        placeholder={t('describe')}
                        value={campaign?.text ?? ''}
                        onChange={onInputChange}
                        rows={3}
                        required
                      />
                      <small className="form-text text-muted">{t('requiredField')}</small>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="effect">
                        {t('effect')}
                      </label>
                      {effectOptions.length > 0 ? (
                        <select
                          className={'form-select'}
                          name="effect"
                          onChange={onInputChange}
                          value={campaign?.effect}
                        >
                          {effectOptions.map((effect) => (
                            <option key={effect.value} value={effect.value}>
                              {effect.title}
                            </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 mb-3">
                    {campaign?.organizationId && campaign?.id ? (
                      <CampaignUploader
                        organizationId={campaign.organizationId}
                        campaignId={campaign.id}
                        onAttachmentsChange={onAttachmentAdd}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="card-block" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <CollapsiblePanel
                  className="mb-4"
                  title={t('utmTags')}
                  isOpen={isUtmTagsOpen}
                  isAnimation={isUtmTagsAnimation}
                  onToggle={utmTagsCollapsePanelHandler}
                >
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-4">
                      <label htmlFor="campaign-utm-campaign" className="form-label">
                        {t('utmCampaign')}:
                      </label>
                      <input
                        type="text"
                        id="campaign-utm-campaign"
                        className="form-control"
                        name="utmCampaign"
                        placeholder={t('utmCampaign')}
                        onChange={onInputChange}
                        value={campaign?.utmCampaign ?? ''}
                      />
                      <small className="form-text text-muted">{t('utmCampaignDescription')}</small>
                      {/* {formErrors?.address_name?.length ? (
                    <FormElementError text={formErrors.address_name[0] || ''} />
                  ) : null} */}
                    </div>
                    <div className="col-12 col-lg-6 mb-4">
                      <label htmlFor="campaign-utm-medium" className="form-label">
                        {t('utmMedium')}:
                      </label>
                      <input
                        type="text"
                        id="campaign-utm-medium"
                        className="form-control"
                        name="utmMedium"
                        placeholder={t('utmMedium')}
                        onChange={onInputChange}
                        value={campaign?.utmMedium ?? ''}
                      />
                      {/* {formErrors?.address_name?.length ? (
                    <FormElementError text={formErrors.address_name[0] || ''} />
                  ) : null} */}
                    </div>
                    <div className="col-12 col-lg-6">
                      <label htmlFor="campaign-utm-source" className="form-label">
                        {t('utmSource')}:
                      </label>
                      <input
                        type="text"
                        id="campaign-utm-source"
                        className={'form-control'}
                        name="utmSource"
                        placeholder={t('utmSource')}
                        onChange={onInputChange}
                        value={campaign?.utmSource ?? ''}
                      />
                      <small className="form-text text-muted">{t('utmSourceDescription')}</small>
                      {/* {formErrors?.address_name?.length ? (
                    <FormElementError text={formErrors.address_name[0] || ''} />
                  ) : null} */}
                    </div>
                  </div>
                </CollapsiblePanel>

                <div className="mb-4">
                  <label htmlFor="when-send-messages" className="form-label">
                    {t('whenSendMessages')}
                  </label>
                  {whenSendMessagesOptions.length > 0 ? (
                    <select
                      id="when-send-messages"
                      className={'form-select'}
                      onChange={whenSendMessagesHandler}
                      value={whenSendMessages}
                    >
                      {whenSendMessagesOptions.map((time) => (
                        <option key={time.value} value={time.value}>
                          {time.title}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
                {whenSendMessages === 'schedule' ? (
                  <div className="mb-4">
                    <label className="form-label">{t('startDate')}</label>
                    <div className="form-control datepicker">
                      <ReactDatePicker
                        name="fromDate"
                        wrapperClassName="input"
                        selected={campaign?.fromDate ? new Date(campaign?.fromDate) : startDate}
                        onChange={(date: Date) => setStartDate(date)}
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                ) : null}

                <CollapsiblePanel
                  title={t('scheduleCampaign')}
                  isOpen={isCustomSendingOptionsOpen}
                  isAnimation={isCustomSendingOptionsAnimation}
                  onToggle={customSendingOptionsCollapsePanelHandler}
                >
                  <>
                    <WeekDaysCheckboxes
                      className="mb-4"
                      onChange={(days) => setCampaign({ ...campaign!, weekDays: days })}
                      days={campaign?.weekDays ?? null}
                    />

                    <div className="row">
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label">{t('fromTime')}:</label>
                        {sendFromTimeOptions.length > 0 ? (
                          <select
                            className={'form-select'}
                            name="fromTime"
                            onChange={onInputChange}
                            value={campaign?.fromTime}
                          >
                            {sendFromTimeOptions.map((time) => (
                              <option key={time.value} value={time.value}>
                                {time.title}
                              </option>
                            ))}
                          </select>
                        ) : null}
                        <small className="form-text text-muted">{t('fromTimeHintText')}</small>
                        {/* {formErrors?.from_time?.length ? <FormElementError text={formErrors.from_time[0] || ''} /> : null} */}
                      </div>
                      <div className="col-12 col-lg-6 mb-3">
                        <label className="form-label">{t('toTime')}:</label>
                        {sendToTimeOptions.length > 0 ? (
                          <select
                            className={'form-select'}
                            name="toTime"
                            onChange={onInputChange}
                            value={campaign?.toTime}
                          >
                            {sendToTimeOptions.map((time) => (
                              <option key={time.value} value={time.value}>
                                {time.title}
                              </option>
                            ))}
                          </select>
                        ) : null}
                        <small className="form-text text-muted">{t('toTimeHintText')}</small>
                        {/* {formErrors?.to_time?.length ? <FormElementError text={formErrors.to_time[0] || ''} /> : null} */}
                      </div>
                      <div className="col-12 col-lg-6">
                        <label className="form-label">{t('timezone')}:</label>
                        {timezoneOptions.length > 0 ? (
                          <select
                            className={'form-select'}
                            name="timezone"
                            onChange={onInputChange}
                            value={campaign?.timezone}
                          >
                            {timezoneOptions.map((timezone) => (
                              <option key={timezone.value} value={timezone.value}>
                                {timezone.title}
                              </option>
                            ))}
                          </select>
                        ) : null}
                        <small className="form-text text-muted">{t('timezoneHintText')}</small>
                        {/* {formErrors?.timezone?.length ? <FormElementError text={formErrors.timezone[0] || ''} /> : null} */}
                      </div>
                    </div>
                  </>
                </CollapsiblePanel>
              </div>
              <div className="d-flex justify-content-between p-4">
                <button type="button" className="btn btn-outline-primary" onClick={() => submitHandler('pending')}>
                  {whenSendMessages === 'schedule' ? t('schedule') : t('sendImmediately')}
                </button>
                <button type="button" className="btn btn-primary" onClick={() => submitHandler('draft')}>
                  {t('saveToDraft')}
                </button>
              </div>{' '}
            </>
          ) : (
            <Spinner top={30} />
          )}
        </div>

        {campaign?.countries && campaign.countries.length > 0 ? (
          <SendTestMessage data={prepareTestMessageData(campaign)} countries={campaign?.countries} />
        ) : null}
      </div>
    </div>
  );
};

export default CreateCampaign;
