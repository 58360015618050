import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ITeam, ITeamOrganization } from '../../types';
import { getAllOrganizationsAPI, getTeamAPI } from '../../api';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import { InviteToTeam } from '../InviteToTeam/InviteToTeam';
import { TeamList } from '../TeamList/TeamList';
import { TeamPendingList } from '../TeamPendingList/TeamPendingList';

export const MyTeam: FC = () => {
  const { token } = useAuthContext();
  const { t } = useTranslation();

  const [team, setTeam] = useState<ITeam>();
  const [isHidden, setIsHidden] = useState<boolean>();
  const [organizations, setOrganizations] = useState<ITeamOrganization[]>([]);

  const fetchTeamOrganizations = async () => {
    try {
      if (!token) return;

      const response = await getAllOrganizationsAPI(token);

      if (response.code) {
        return;
      }

      if ('hidden' in response) setIsHidden(response.hidden);
      setOrganizations(response.items);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTeamData = async () => {
    try {
      if (!token) return;

      const response = await getTeamAPI(token);

      if (response.code) {
        return;
      }

      setTeam(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeamData();
    fetchTeamOrganizations();
  }, []);

  if (isHidden) return null;

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{t('myTeam')}</h5>
        </div>
      </div>
      <div className="card-block">
        <InviteToTeam onInviteSent={fetchTeamData} />

        {team?.participants.length && organizations.length ? (
          <TeamList
            participants={team.participants}
            organizations={organizations}
            onDeleteParticipant={fetchTeamData}
          />
        ) : null}

        {team?.invited.length ? <TeamPendingList participants={team.invited} onRevokeInvite={fetchTeamData} /> : null}
      </div>
    </div>
  );
};
