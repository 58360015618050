import { API_ENDPOINT } from '../config/environment';
import { errorsHandler } from './utils';

export const audienceAPIGetAudienceData = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/audience-list/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const audienceAPIGetAudienceDetailsData = async (id: string, token: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/audience/${id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const audienceAPIDeleteContact = async (token: string, ids: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/audience-list/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(ids),
  });
  return errorsHandler(response);
};

export const audienceAPIGetFilters = async (token: string, filterQuery: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/audience-list/?` + new URLSearchParams(filterQuery), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};
