import { FC, SelectHTMLAttributes, useState } from 'react';
import { Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import FeatherIcon from 'feather-icons-react';

import { Chip } from '../Chip/Chip';

import './Select.css';

interface IOption {
  id: string;
  label: string;
  value: string;
}

const SPACE = '\u00A0';

interface IProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  options: IOption[];
  label?: string;
  placeholder?: string;
  initialValue?: IOption | IOption[];
  onChange?: (value: IOption) => void;
}

export const Select: FC<IProps> = (props) => {
  const { options, label, placeholder = SPACE, initialValue, id, onChange, ...otherProps } = props;
  const [selected, setSelected] = useState(
    initialValue ? initialValue : otherProps.multiple ? [options[1]] : options[1]
  );

  const changeHandler = (value: IOption) => {
    onChange && onChange(value);
    setSelected(value);
  };

  return (
    <Field>
      {label ? <Label className="form-label">{label}</Label> : null}
      <Listbox value={selected} onChange={changeHandler} {...otherProps}>
        {otherProps.multiple ? (
          <ListboxButton
            className="form-select form-select-sm text-start d-flex flex-wrap gap-2"
            style={{ maxWidth: 400 }}
          >
            {selected instanceof Array && selected.length
              ? selected.map((option) => (
                  <Chip key={option.id} style={{ maxWidth: 170 }} title={option.label}>
                    {option.label}
                  </Chip>
                ))
              : placeholder}
          </ListboxButton>
        ) : (
          <ListboxButton className="form-select form-select-sm text-start">
            {'label' in selected ? selected.label : placeholder}
          </ListboxButton>
        )}

        <ListboxOptions
          anchor="bottom"
          transition
          className={'select__list rounded-2 border border-light bg-white shadow p-1 focus:outline-none'}
        >
          {options.map((option) => (
            <ListboxOption
              key={option.id}
              value={option}
              className="select__option d-flex align-items-center py-2 px-3 rounded-2 text-black"
            >
              <FeatherIcon icon="check" size={18} className="select__selected-icon mr-2" />
              <div className="text-black">{option.label}</div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </Field>
  );
};
