import type { InferOutput } from 'valibot';

import { pipe, object, transform, optional, string, nonEmpty, any, array, omit, pick } from 'valibot';

// const stringNonEmptySchema = (msg: string) => pipe(string(), nonEmpty(msg));

export const DefaultValuesFormSchema = object({
  // facebook_url: union([literal(''), pipe(string(), url())]),
  // first_name: optional(pipe(string(), nonEmpty('Please enter your first name.'))),
  day_weeks: optional(array(string())),
  from_time: optional(string()),
  opt_in_text: pipe(string(), nonEmpty('Please enter the opt-in text.')),
  post_welcome_message: object({
    subject: optional(string()),
    text: optional(string()),
    attachments: optional(
      pipe(
        string(),
        transform((input) => JSON.parse(input))
      )
    ),
    vcard: optional(
      pipe(
        any(),
        transform((input) => input === 'on')
      )
    ),
  }),
  timezone: optional(string()),
  to_time: optional(string()),
  utm_campaign: optional(string()),
  utm_medium: optional(string()),
  utm_source: optional(string()),
  welcome_message: object({
    subject: optional(string()),
    text: pipe(string(), nonEmpty('Please enter the welcome text.')),
    attachments: optional(
      pipe(
        string(),
        transform((input) => JSON.parse(input))
      )
    ),
    vcard: optional(
      pipe(
        any(),
        transform((input) => input === 'on')
      )
    ),
  }),
});

export const DefaultValuesFormWithOutWelcomeMessageSchema = omit(DefaultValuesFormSchema, [
  'welcome_message',
  'post_welcome_message',
]);
export const DefaultValuesFormOnlyWelcomeMessageSchema = pick(DefaultValuesFormSchema, [
  'welcome_message',
  'post_welcome_message',
]);
// forward(
//   partialCheck([['is_organization'], ['first_name']], (input) => {
//     return !input.is_organization
//       ? safeParse(stringNonEmptySchema('Please enter your first name.'), input.first_name).success
//       : true;
//   }),
//   ['first_name']
// )

export type IDefaultValuesForm = InferOutput<typeof DefaultValuesFormSchema>;
