/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ErrorPopupProps = {
  isPopup: boolean;
  errorCode: string | number;
  popupClose?: (c: boolean) => void;
};

const ErrorPopup: React.FC<ErrorPopupProps> = ({ isPopup, errorCode, popupClose }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(isPopup);

  const onClose = useCallback(() => {
    setShow(false);
    if (popupClose) {
      popupClose(false);
    }
  }, [popupClose]);

  return (
    <>
      {show ? (
        <div className="modals-container" style={{ zIndex: 2000 }}>
          <div className="vfm vfm--inset vfm--fixed" style={{ zIndex: 1000 }}>
            <div className="vfm__overlay vfm--overlay vfm--absolute vfm--inset"></div>
            <div
              className="vfm__container vfm--absolute vfm--inset vfm--outline-none"
              aria-expanded="true"
              role="dialog"
              aria-modal="true"
            >
              <div className="vfm__content">
                <div className="modal-dialog modal-dialog-start" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{t('error')}</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3">
                        <p className="mb-0">{errorCode}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default ErrorPopup;
