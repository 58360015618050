import { FC, ReactNode } from 'react';
import FeatherIcon from 'feather-icons-react';

import './CollapsiblePanel.css';

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  onToggle?: () => void;
  className?: string;
  title?: string;
  isAnimation?: boolean;
}
export const CollapsiblePanel: FC<IProps> = (props) => {
  const { children, isAnimation, isOpen, onClose, onToggle, title, className = '' } = props;

  const classes =
    'panel' +
    `${isAnimation ? ' panel--animation' : ''}` +
    `${isOpen ? ' panel--open' : ''}` +
    (className ? ` ${className}` : '');

  return (
    <div className={classes}>
      {title ? (
        <div className="panel__header" onClick={onToggle}>
          <h5 className="mb-0">{title}</h5>
          <button type="button" className="panel__toggle-btn p-2 lh-1">
            <FeatherIcon icon="chevron-right" size={20} />
          </button>
        </div>
      ) : null}
      <div className="panel__body">{children}</div>
    </div>
  );
};
