/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React from 'react';
import '../../index.css';

type PhoneCodeSelectProps = {
  onPhoneRegion: (country: string) => void;
  onBlur: () => void;
  data: any;
};
const PhoneCodeSelect: React.FC<PhoneCodeSelectProps> = ({ onPhoneRegion, onBlur, data }) => {
  return (
    <div className="t-a-l">
      <select
        onBlur={onBlur}
        onChange={(e) => onPhoneRegion?.(e.target.value)}
        id="phone_number_region"
        name="phone_number_region"
        className="form-control"
      >
        {data.map((c: any, index: number) => {
          return (
            <option key={index} defaultValue={data[0].code}>
              {c.code}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default PhoneCodeSelect;
