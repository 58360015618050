import { AxiosError } from 'axios';

import { API_ENDPOINT } from '../config/environment';
import { errorsHandler } from './utils';
import { axiosApiErrorHandler, ErrorResponse } from '../lib/axiosApiErrorHandler';
import { baseApi } from './baseApi';

export const getOrganizationDetailsDataAPI = async (id: string, token: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/organization/${id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const updateOrganizationDetailsAPI = async (id: string, token: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/organization/${id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const getDefaultValuesCreateOrganizationAPI = async () => {
  let result;

  try {
    result = await baseApi.get(`dashboard/organization/new/`);
  } catch (error) {
    result = axiosApiErrorHandler(error as AxiosError<ErrorResponse>);
  }

  return result;
};

export const createOrganizationAPI = async (data: any) => {
  let result;

  try {
    result = await baseApi.post(`dashboard/organization/new/`, data);
  } catch (error) {
    result = axiosApiErrorHandler(error as AxiosError<ErrorResponse>);
  }

  return result;
};
