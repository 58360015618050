/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { NavLink, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';
import FeatherIcon from 'feather-icons-react';

const CustomNavLink: React.FC<{
  to?: To;
  title: string;
  icon?: string;
  chevron?: boolean;
  rotate?: boolean;
  inner?: boolean;
}> = ({ to, title, icon, chevron, rotate, inner }) => {
  // const resolved = useResolvedPath(to as string);
  // const match = useMatch({ path: resolved.pathname, end: true });
  const { t } = useTranslation();

  return (
    <NavLink to={to as string} className="nav-item">
      {({ isActive }) =>
        !inner ? (
          <span className={'home-link' + `${isActive ? ' active' : ''}`}>
            <span className="pcoded-micon">
              <FeatherIcon icon={icon as string} size={16} />
            </span>
            <span className="pcoded-mtext">{t(title)}</span>
            {chevron ? (
              <FeatherIcon className={rotate ? 'chevron rotate' : 'chevron'} icon="chevron-right" size={16} />
            ) : null}
          </span>
        ) : (
          <div className="d-flex align-items-center">
            <span className={'hidden' + `${isActive ? ' active' : ''}`} />
            <span>
              {t(title)} {icon ? <FeatherIcon className="pcoded-micon" icon="external-link" size={16} /> : null}
            </span>
          </div>
        )
      }
    </NavLink>
  );
};

type AccordionButtonElementProps = {
  item: any;
  index: number;
  isOpenNav: boolean;
  isShow?: boolean;
  onOpen?: (c: boolean) => void;
  onClick?: (i: number) => void;
};

const AccordionButtonElement: React.FC<AccordionButtonElementProps> = ({
  item,
  index,
  isOpenNav,
  onOpen,
  onClick,
  isShow,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(isOpenNav);

  const showItem = (i: number) => {
    if (onClick) {
      onClick(i);
    }
  };

  const onClose = () => {
    setIsOpen(!isOpen);
    if (onOpen && isOpen !== undefined) {
      onOpen(!isOpenNav);
    }
  };

  const handleClick = () => {
    onClose();
  };

  return (
    <>
      {!item.items?.length ? (
        <CustomNavLink icon={item.icon} title={item.title} to={item.href} />
      ) : (
        <Collapsible
          trigger={
            <div className="nav-item pcoded-hasmenu" onClick={() => showItem(index)}>
              <span className="home-link">
                <span className="pcoded-micon">
                  <FeatherIcon icon={item.icon} size={16} />
                </span>
                <span className="pcoded-mtext">{t(item.title)}</span>
                <FeatherIcon className={isShow ? 'chevron rotate' : 'chevron'} icon="chevron-right" size={16} />
              </span>
            </div>
          }
          open={isShow}
        >
          <ul className="pcoded-submenu">
            {item.items.map((item: any, i: number) => (
              <li key={i}>
                <CustomNavLink icon={item.icon} title={item.text} to={item.href} inner />
              </li>
            ))}
          </ul>
        </Collapsible>
      )}
    </>
  );
};

export default AccordionButtonElement;
