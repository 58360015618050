import { FormEvent, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten, safeParse as valibotSafeParse } from 'valibot';

import { getUnifiedFormData } from '../../../lib/getUnifiedFormData';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import {
  DefaultValuesFormOnlyWelcomeMessageSchema,
  DefaultValuesFormSchema,
  DefaultValuesFormWithOutWelcomeMessageSchema,
} from '../../../schemes';
import { IOrganizationDefaultValues } from '../../../types';
import { updateOrganizationDetailsAPI } from '../../../api';
import { FormElementError } from '../../common';
import Toast from '../../toasts/Toast';
import { OrgWelcomeMessageForm } from '../OrgWelcomeMessageForm/OrgWelcomeMessageForm';

interface IProps {
  id?: string;
  defaultValues?: IOrganizationDefaultValues | null;
  onSuccessSubmit?: (data: any) => void;
  onFailureSubmit?: (response: any) => void;
  showSubmitBtn?: boolean;
  showWelcomeMessage?: boolean;
  disableSubmit?: boolean;
  organizationId?: string;
  onlyWelcomeMessageForm?: boolean;
}

export type Ref = HTMLFormElement;

export const OrgDefaultValuesForm = forwardRef<Ref, IProps>((props, ref) => {
  const {
    id = 'org-default-values-form',
    defaultValues,
    onSuccessSubmit,
    onFailureSubmit,
    showSubmitBtn = true,
    showWelcomeMessage = true,
    disableSubmit = false,
    organizationId,
    onlyWelcomeMessageForm = false,
  } = props;
  const { token } = useAuthContext();
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<any>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false);

  const submitDefaultValuesFormHandler = async (e: FormEvent) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const parseFormData = getUnifiedFormData(target);

    // console.log('parseFormData', parseFormData);

    const validate = valibotSafeParse(
      onlyWelcomeMessageForm
        ? DefaultValuesFormOnlyWelcomeMessageSchema
        : showWelcomeMessage
          ? DefaultValuesFormSchema
          : DefaultValuesFormWithOutWelcomeMessageSchema,
      parseFormData
    );

    // console.log('validate', validate);

    if (validate.success) {
      setFormErrors(undefined);

      if (onSuccessSubmit) {
        onSuccessSubmit({ default_values: validate.output });
      }

      if (!disableSubmit) {
        try {
          if (!token || !organizationId) {
            console.error('You miss token or organizationId');
            return;
          }

          setIsFormSubmitting(true);

          const res = await updateOrganizationDetailsAPI(organizationId, token, { default_values: validate.output });

          if (res.code) {
            onFailureSubmit && onFailureSubmit(res);
          }

          setIsFormSuccess(true);
          setTimeout(() => {
            setIsFormSuccess(false);
          }, 3000);
        } catch (error) {
          console.error(error);
        } finally {
          setIsFormSubmitting(false);
        }
      }
    } else {
      const flattenErrors = flatten<typeof DefaultValuesFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
      // console.log('formErrors', flattenErrors.nested);
    }
  };

  return (
    <>
      <form id={id} ref={ref} onSubmit={submitDefaultValuesFormHandler}>
        {!onlyWelcomeMessageForm ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="alert alert-info" role="alert">
                  {t('daysWeekInfoAlert')}.
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">{t('daysWeekOfSending')}:</label>
                  <div className="">
                    <div className="form-check form-check-inline">
                      <input
                        id="monday-cb"
                        className="form-check-input input-primary"
                        type="checkbox"
                        name="day_weeks"
                        defaultChecked={defaultValues?.weekDays.includes('monday')}
                        value="monday"
                      />
                      <label className="form-check-label" htmlFor="monday-cb">
                        {t('weekdays.monday')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="tuesday-cb"
                        className="form-check-input input-primary"
                        type="checkbox"
                        name="day_weeks"
                        defaultChecked={defaultValues?.weekDays.includes('tuesday')}
                        value="tuesday"
                      />
                      <label className="form-check-label" htmlFor="tuesday-cb">
                        {t('weekdays.tuesday')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="wednesday-cb"
                        className="form-check-input input-primary"
                        type="checkbox"
                        name="day_weeks"
                        defaultChecked={defaultValues?.weekDays.includes('wednesday')}
                        value="wednesday"
                      />
                      <label className="form-check-label" htmlFor="wednesday-cb">
                        {t('weekdays.wednesday')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="thursday-cb"
                        className="form-check-input input-primary"
                        type="checkbox"
                        name="day_weeks"
                        defaultChecked={defaultValues?.weekDays.includes('thursday')}
                        value="thursday"
                      />
                      <label className="form-check-label" htmlFor="thursday-cb">
                        {t('weekdays.thursday')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="friday-cb"
                        className="form-check-input input-primary"
                        type="checkbox"
                        name="day_weeks"
                        defaultChecked={defaultValues?.weekDays.includes('friday')}
                        value="friday"
                      />
                      <label className="form-check-label" htmlFor="friday-cb">
                        {t('weekdays.friday')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="saturday-cb"
                        className="form-check-input input-primary"
                        type="checkbox"
                        name="day_weeks"
                        defaultChecked={defaultValues?.weekDays.includes('saturday')}
                        value="saturday"
                      />
                      <label className="form-check-label" htmlFor="saturday-cb">
                        {t('weekdays.saturday')}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        id="sunday-cb"
                        className="form-check-input input-primary"
                        type="checkbox"
                        name="day_weeks"
                        defaultChecked={defaultValues?.weekDays.includes('sunday')}
                        value="sunday"
                      />
                      <label className="form-check-label" htmlFor="sunday-cb">
                        {t('weekdays.sunday')}
                      </label>
                    </div>
                  </div>
                  <small className="form-text text-muted">{t('daysOfWeekHintText')}</small>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">{t('fromTime')}:</label>
                      {defaultValues && defaultValues?.sendFromTime.length > 0 ? (
                        <select
                          className={'form-select' + (formErrors?.from_time?.length ? ' is-invalid' : '')}
                          name="from_time"
                          defaultValue={defaultValues?.fromTime}
                        >
                          {defaultValues?.sendFromTime.map((time, idx) => (
                            <option key={idx} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      ) : null}
                      <small className="form-text text-muted">{t('fromTimeHintText')}</small>
                      {formErrors?.from_time?.length ? <FormElementError text={formErrors.from_time[0] || ''} /> : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">{t('toTime')}:</label>
                      {defaultValues && defaultValues?.sendToTime.length > 0 ? (
                        <select
                          className={'form-select' + (formErrors?.to_time?.length ? ' is-invalid' : '')}
                          name="to_time"
                          defaultValue={defaultValues?.toTime}
                        >
                          {defaultValues?.sendToTime.map((time, idx) => (
                            <option key={idx} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      ) : null}
                      <small className="form-text text-muted">{t('toTimeHintText')}</small>
                      {formErrors?.to_time?.length ? <FormElementError text={formErrors.to_time[0] || ''} /> : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">{t('timezone')}:</label>
                      {defaultValues && defaultValues?.availableTimezones.length > 0 ? (
                        <select
                          className={'form-select' + (formErrors?.timezone?.length ? ' is-invalid' : '')}
                          name="timezone"
                          defaultValue={defaultValues?.timezone}
                        >
                          {defaultValues?.availableTimezones.map((t) => (
                            <option key={t.id} value={t.id}>
                              {t.name}
                            </option>
                          ))}
                        </select>
                      ) : null}
                      <small className="form-text text-muted">{t('timezoneHintText')}</small>
                      {formErrors?.timezone?.length ? <FormElementError text={formErrors.timezone[0] || ''} /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-4" />

            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label">{t('optInText')}:</label>
                  <input
                    type="text"
                    className={'form-control' + (formErrors?.opt_in_text?.length ? ' is-invalid' : '')}
                    name="opt_in_text"
                    placeholder="Opt-in text"
                    defaultValue={defaultValues?.optInText}
                  />
                  <small className="form-text text-muted">{t('optInHintText')}</small>
                  {formErrors?.opt_in_text?.length ? <FormElementError text={formErrors.opt_in_text[0] || ''} /> : null}
                </div>
              </div>
            </div>

            <hr className="my-4" />

            <div className="row">
              <div className="col-12">
                <div className="alert alert-info" role="alert">
                  {t('utmInfoAlert')}.
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label">{t('utmCampaign')}:</label>
                  <input
                    type="text"
                    className={'form-control' + (formErrors?.address_name?.length ? ' is-invalid' : '')}
                    name="utm_campaign"
                    placeholder="UTM campaign"
                    defaultValue={defaultValues?.utmCampaign}
                  />
                  {formErrors?.address_name?.length ? (
                    <FormElementError text={formErrors.address_name[0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label">{t('utmMedium')}:</label>
                  <input
                    type="text"
                    className={'form-control' + (formErrors?.address_name?.length ? ' is-invalid' : '')}
                    name="utm_medium"
                    placeholder="UTM medium"
                    defaultValue={defaultValues?.utmMedium}
                  />
                  {formErrors?.address_name?.length ? (
                    <FormElementError text={formErrors.address_name[0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label">{t('utmSource')}:</label>
                  <input
                    type="text"
                    className={'form-control' + (formErrors?.address_name?.length ? ' is-invalid' : '')}
                    name="utm_source"
                    placeholder="UTM source"
                    defaultValue={defaultValues?.utmSource}
                  />
                  {formErrors?.address_name?.length ? (
                    <FormElementError text={formErrors.address_name[0] || ''} />
                  ) : null}
                </div>
              </div>
            </div>

            {showWelcomeMessage ? <hr className="my-4" /> : null}
          </>
        ) : null}

        {showWelcomeMessage ? (
          <OrgWelcomeMessageForm
            // ref={orgWelcomeMessageFormRef}
            organizationId={organizationId}
            showSubmitBtn={false}
            defaultValues={{
              welcomeMessage: defaultValues?.welcomeMessage,
              postWelcomeMessage: defaultValues?.postWelcomeMessage,
            }}
          />
        ) : null}

        {showSubmitBtn ? (
          <button className="btn btn-primary mt-2" type="submit" disabled={isFormSubmitting}>
            {isFormSubmitting ? t('sending') : t('save')}
          </button>
        ) : null}
      </form>

      {isFormSuccess ? (
        <div className="mt-4">
          <Toast message={t('defaultValuesSaved') + '!'} />
        </div>
      ) : null}
    </>
  );
});
