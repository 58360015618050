import { AxiosError } from 'axios';
import { Navigate } from 'react-router-dom';

export interface ErrorResponse {
  code: number;
  message: string;
  success: boolean;
}

export const axiosApiErrorHandler = (error: AxiosError<ErrorResponse>) => {
  if (error.response) {
    const data = error.response.data;
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(data);
    console.log(error.response.status);
    console.log(error.response.headers);

    if (data.code === 120) {
      window.location.assign('/login')
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
};
