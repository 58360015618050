/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

interface ModalProps {
  isOpen: boolean;
  onOpen: (open: boolean) => void;
  data: any;
}

const AudienceDetailsModal: React.FC<ModalProps> = ({ isOpen, data, onOpen }) => {
  const [show, setShow] = useState(isOpen);

  const onClose = useCallback(() => {
    setShow(!show);
    onOpen(false);
  }, [onOpen, show]);

  const { t } = useTranslation();
  const lang = 'languages_real.' + data.language;

  return (
    <>
      {show ? (
        <div className="modals-container">
          <div className="vfm vfm--inset vfm--fixed" style={{ zIndex: 1000 }}>
            <div className="vfm__overlay vfm--overlay vfm--absolute vfm--inset"></div>
            <div
              className="vfm__container vfm--absolute vfm--inset vfm--outline-none"
              aria-expanded="true"
              role="dialog"
              aria-modal="true"
            >
              <div className="vfm__content">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{t('messageDetails')}</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {data.value ? (
                        <div className="mb-3">
                          <p className="font-weight-bold mb-0">{t('contact')}</p>
                          <p className="mb-0">{data.value}</p>
                        </div>
                      ) : null}
                      {data.value_type ? (
                        <div className="mb-3">
                          <p className="font-weight-bold mb-0">{t('type')}</p>
                          <p className="mb-0">{t(data.value_type)}</p>
                        </div>
                      ) : null}
                      {data.language ? (
                        <div className="mb-3">
                          <p className="font-weight-bold mb-0">{t('language')}</p>
                          <p className="mb-0">{t(lang)}</p>
                        </div>
                      ) : null}
                      {data.region ? (
                        <div className="mb-3">
                          <p className="font-weight-bold mb-0">{t('region')}</p>
                          <p className="mb-0">{data.region}</p>
                        </div>
                      ) : null}
                      {data.carrier ? (
                        <div className="mb-3">
                          <p className="font-weight-bold mb-0">{t('carrier')}</p>
                          <p className="mb-0">{data.carrier}</p>
                        </div>
                      ) : null}
                      {data.create_date ? (
                        <div className="mb-3">
                          <p className="font-weight-bold mb-0">{t('optInDate')}</p>
                          <p className="mb-0">
                            {DateTime.fromISO(`${data.create_date}`).toLocaleString({
                              year: 'numeric',
                              month: 'long',
                              day: '2-digit',
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default AudienceDetailsModal;
