import { FC, PropsWithChildren, CSSProperties } from 'react';

interface IProps {
  variant?: 'primary' | 'secondary' | 'success' | 'error' | 'info';
  style?: CSSProperties;
  title?: string;
}

export const Chip: FC<PropsWithChildren<IProps>> = (props) => {
  const { children, style, title } = props;
  return (
    <div className="d-inline-flex px-4 py-1 rounded-pill bg-primary" style={style} title={title}>
      <span className="text-truncate text-white">{children}</span>
    </div>
  );
};
