import type { FC, ReactElement, ReactNode } from 'react';

import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Menu as HLMenu, MenuButton, MenuItem, MenuItems, MenuSeparator, Transition } from '@headlessui/react';
import FeatherIcon from 'feather-icons-react';

const classes: any = [];

interface IDropdownItem {
  label: string;
  hotkey?: string;
  icon?: ReactElement;
  onClick?: () => void;
  href?: string;
}

export interface IProps {
  children: ReactNode;
  // items: IDropdownItem[];
}

export const Dropdown = memo<IProps>(function Dropdown(props) {
  const { children } = props;
  return (
    <div>
      <HLMenu>{children}</HLMenu>
    </div>
  );
});
/*
{items.map(({ icon: Icon, label, hotkey, onClick, href }, idx) => (
  <MenuItem as="li" key={idx}>
    <Button
      link={Boolean(href)}
      to={href}
      onClick={onClick}
      className={classes.dropdown__item}
    >
      {Icon ? <Icon className={classes['dropdown__item-icon']} /> : null}
      {label}
      {hotkey
        ? (
          <kbd
            className={classes['dropdown__item-kbd']}
          >
            ⌘
            {hotkey}
          </kbd>
          )
        : null }

    </Button>
  </MenuItem>
))}
*/
interface ITrigger {
  children: ReactNode;
  arrow?: boolean;
}
export const Trigger: FC<ITrigger> = ({ children, arrow = true, ...otherProps }) => {
  return (
    <MenuButton className="btn btn-outline-primary d-flex align-items-center">
      {children}
      {arrow ? <FeatherIcon icon="chevron-down" size={20} className="ml-2" /> : null}
    </MenuButton>
  );
};
Trigger.displayName = 'Dropdown.Trigger';

interface IMenu {
  children: ReactNode;
}

export const Menu: FC<IMenu> = ({ children, ...otherProps }) => {
  return (
    <Transition
      enter="dropdown-menu__enter"
      enterFrom="dropdown-menu__fade-out"
      enterTo="dropdown-menu__fade-in"
      leave="dropdown-menu__leave"
      leaveFrom="dropdown-menu__fade-in"
      leaveTo="dropdown-menu__fade-out"
    >
      <MenuItems as="ul" anchor="bottom end" className="dropdown-menu show" {...otherProps}>
        {children}
      </MenuItems>
    </Transition>
  );
};
Menu.displayName = 'Dropdown.Menu';

interface IItem {
  children: ReactNode;
  shortcut?: string;
  href?: string;
  iconStart?: string;
  iconEnd?: string;
  onClick?: () => void;
}

export const Item: FC<IItem> = (props) => {
  const { children, shortcut, href, onClick, iconStart, iconEnd, ...otherProps } = props;

  const Tag: typeof Link | keyof JSX.IntrinsicElements = href ? Link : 'button';
  return (
    <MenuItem className="" as="li" {...otherProps}>
      <Tag className="dropdown-item" to={href!} onClick={onClick}>
        {iconStart ? <FeatherIcon icon={iconStart} /> : null}
        {children}
        {iconEnd ? <FeatherIcon icon={iconEnd} /> : null}
        {/* {shortcut ? <kbd className={classes['dropdown__item-el-kbd']}>⌘{shortcut}</kbd> : null} */}
      </Tag>
    </MenuItem>
  );
};
Item.displayName = 'Dropdown.Item';

export const Divider: FC = () => {
  return <MenuSeparator as="hr" />;
};
Divider.displayName = 'Dropdown.Divider';
