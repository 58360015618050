import { FC, ImgHTMLAttributes } from 'react';

const SIZES = {
  xs: 'wid-20 hei-20',
  sm: 'wid-30 hei-30',
  md: 'wid-40 hei-40',
  lg: 'wid-80 hei-80',
  xl: 'wid-100 hei-100',
} as const;

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  imgSrc?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  initials?: string;
}
export const Avatar: FC<IProps> = (props) => {
  const { imgSrc, size = 'md', initials, ...otherProps } = props;

  const classes = (): string => {
    let classes = 'img-radius';
    if (initials) classes += ' d-flex align-items-center justify-content-center bg-gray-200 fw-bolder';
    if (size) classes += ` ${SIZES[size]}`;

    return classes;
  };

  return (
    <div className="d-inline-flex">
      {imgSrc ? (
        <img className={classes()} src={imgSrc} alt="avatar" />
      ) : (
        <div className={classes()} {...otherProps}>
          {initials}
        </div>
      )}
    </div>
  );
};
