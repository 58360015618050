import { API_ENDPOINT } from '../config/environment';
import { errorsHandler } from './utils';

export const campaignsAPIGetCampaignsData = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/campaigns/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const campaignsAPIGetCampaignsFilterData = async (token: string, filterQuery: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/campaigns/?` + new URLSearchParams(filterQuery), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const campaignsAPIDeleteCampaignData = async (token: string, ids: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/campaigns/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(ids),
  });
  return errorsHandler(response);
};

export const campaignsAPIGetCreateCampaign = async (token: string, id: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/campaign/new/${id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const campaignsAPIPostCreateCampaign = async (token: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/campaign/create/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const campaignsAPIPutCampaignDetailsData = async (id: string, token: string | null | undefined, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/campaign/${id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const campaignsAPIGetCampaignDetailsData = async (id: string, token: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/campaign/${id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const campaignsAPICreateCommand = async (orgId: string, token: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/command/${orgId}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const campaignsAPIUpdateCommand = async (
  { orgId, commandId }: { orgId: string; commandId: string },
  token: string,
  data: any
) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/command/${orgId}/${commandId}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};
export const campaignsAPIDeleteCommand = async (
  { orgId, commandId }: { orgId: string; commandId: string },
  token: string
) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/command/${orgId}/${commandId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const campaignsAPIPostTestMessage = async (token: string | null | undefined, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/campaign/test-message/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });

  return errorsHandler(response);
};
