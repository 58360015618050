import { FC, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  title: string;
  text: string | ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  dangerConfirmButton?: boolean;
  isLoading?: boolean;
}

export const ConfirmModal: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const {
    isOpen,
    title,
    text,
    onConfirm,
    onCancel,
    confirmText = t('Confirm'),
    cancelText = t('cancel'),
    dangerConfirmButton,
    isLoading,
  } = props;

  const btnClasses = ['btn'];
  if (dangerConfirmButton) {
    btnClasses.push('btn-danger');
  } else {
    btnClasses.push('btn-primary');
  }

  const onCancelHandler = useCallback(() => {
    onCancel();
  }, [isOpen]);

  const onConfirmHandler = () => {
    onConfirm();
  };

  return isOpen ? (
    <div>
      <div className="modal-container modal-container_top" />
      <div
        className="modal modal_top fade show"
        role="dialog"
        aria-hidden="true"
        style={{ display: 'block', zIndex: 2000 }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLiveLabel">
                {title}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancelHandler}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{text}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onCancelHandler}>
                {cancelText}
              </button>
              <button type="button" className={btnClasses.join(' ')} onClick={onConfirmHandler} disabled={isLoading}>
                {confirmText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
