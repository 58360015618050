import type { InferOutput } from 'valibot';

import { pipe, object, string, url, nonEmpty } from 'valibot';

export const OrganizationDetailsFormSchema = object({
  country: pipe(string(), nonEmpty('Please choose a country.')),
  name: pipe(string(), nonEmpty('Please enter the name.')),
  support_url: pipe(string(), url('Please enter the correct url. Should start with https://')),
  website: pipe(string(), url('Please enter the correct url. Should start with https://')),
});

export type IOrganizationDetailsForm = InferOutput<typeof OrganizationDetailsFormSchema>;
