import { API_ENDPOINT } from '../config/environment';
// import { errorsHandler } from './utils';

export const authAPIGetSignUpData = async () => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/signup/');

  return response.json();
};

export const authAPIGetSignInData = async (id: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/auth/signup/${id}/`);
  return response.json();
};

export const authAPIPutSignUpData = async (id: string, data: Record<string, string | number>) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/auth/signup/${id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(data),
  });
  return response.json();
};

export const authAPIPostSignUpData = async (data: Record<string, string | number>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/send/signup/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

export const authAPIPostSignIn = async (data: Record<string, string | number>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/send/signin/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const authAPIPostVerifyCode = async (data: Record<string, string | number>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/verify/signup/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const authAPIPostAddPassword = async (token: string, data: Record<string, string | number | undefined>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/add-password/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const authAPIPostOtpVerifySignIn = async (data: Record<string, string | number | undefined>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/verify/signin/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const authAPIPostPasswordVerifySignIn = async (data: Record<string, string | number | undefined>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/verify-password/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const authAPIPostOtpRestorePassword = async (data: Record<string, string | number | undefined>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/send/restore/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const authAPIPostRestorePassword = async (data: Record<string, string | number | undefined>) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/auth/verify/restore/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};
