import { ICampaign } from '../../../types';

export function prepareTestMessageData(data: ICampaign) {
  return {
    contact: '',
    organizationId: data.organizationId,
    text: data.text,
    subject: data.subject,
    effect: data.effect,
    attachments: data.attachments,
  };
}
