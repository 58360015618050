interface PendingItemDTO {
  id: string;
  credits: number;
  create_date: string;
}

export function mapPendingItems(items: PendingItemDTO[]) {
  if (Array.isArray(items) && items.length === 0) {
    return [];
  }

  return items.map((item) => ({
    id: item.id,
    credits: item.credits,
    createdAt: item.create_date,
  }));
}
