import { IVCardDTO } from '../../types';

export function mapVCard(card: IVCardDTO) {
  if (Object.keys(card).length === 0) {
    return null;
  }

  return {
    addressName: card.address_name,
    birthday: card.birthday,
    city: card.city,
    country: card.country,
    downloadUrl: card.download_url,
    email: card.email,
    facebookUrl: card.facebook_url,
    firstName: card.first_name,
    instagramUrl: card.instagram_url,
    isOrganization: card.is_organization,
    lastName: card.last_name,
    linkedinUrl: card.linkedin_url,
    middleName: card.middle_name,
    needAddAddress: card.need_add_address,
    note: card.note,
    noteFooter: card.note_footer,
    noteHeader: card.note_header,
    organizationName: card.organization_name,
    phoneNumber: card.phone_number,
    photoUrl: card.photo_url,
    postalCode: card.postal_code,
    state: card.state,
    street: card.street,
    supportUrl: card.support_url,
    twitterUrl: card.twitter_url,
    websiteUrl: card.website_url,
  };
}
