import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './BankCard.css';

interface IProps {
  name: string;
  type: 'card';
  expireDate: string;
  last4Digits: string;
  cardType: 'visa' | 'mastercard';
  onDelete: () => void;
  canDelete?: boolean;
  defaultCard?: boolean;
}

export const BankCard: FC<IProps> = (props) => {
  const { name, expireDate, last4Digits, onDelete, canDelete, defaultCard } = props;
  const { t } = useTranslation();

  const onClickHandler = () => {
    onDelete();
  };

  return (
    <div
      className={'card rounded-4 bank-card' + `${defaultCard ? ' bank-card--default' : ''}`}
      style={{
        backgroundImage: 'url(/assets/images/widget/img-card-bg.svg)',
        backgroundSize: 'cover',
      }}
      data-default-text={t('default')}
    >
      <div className="card-body">
        <div className="d-flex">
          <div className="flex-grow-1 me-3">
            <p className="text-white text-sm text-opacity-50 text-uppercase mb-0">Card name</p>
            <h5 className="text-white">{name}</h5>
          </div>
          {/* <div className="flex-shrink-0">
            <img src="/assets/images/widget/img-card-master.svg" alt="card type" className="img-fluid" />
          </div> */}
        </div>
        <h4 className="text-white mt-5 mb-2">**** **** **** **** {last4Digits}</h4>
        <div className="row">
          <div className="col-auto">
            <p className="text-white text-sm text-opacity-50 text-uppercase mb-0">Exp</p>
            <h6 className="text-white mb-0">{expireDate}</h6>
          </div>
          <div className="col-auto">
            <p className="text-white text-sm text-opacity-50 text-uppercase mb-0">Cvv</p>
            <h6 className="text-white mb-0">***</h6>
          </div>
        </div>
        {canDelete ? (
          <button
            type="button"
            className="btn btn-danger btn-sm position-absolute bottom-0 start-0 end-0 bank-card__delete-btn rounded-bottom-4"
            onClick={onClickHandler}
          >
            {t('delete')}
          </button>
        ) : null}
      </div>
    </div>
  );
};
