import { deleteObject, StorageReference } from 'firebase/storage';

export const deleteFromFirebaseStorage = async (fileStorageRef: StorageReference): Promise<void> => {
  try {
    return await deleteObject(fileStorageRef);
  } catch (error) {
    console.error('An error occurred when deleting from the storage', error);
    // throw error;
  }
};
