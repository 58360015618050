/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from './Accordion';

interface SidebarProps {
  isOpen?: boolean;
  onOpen?: (c: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onOpen }) => {
  const { t } = useTranslation();

  const [isMobile] = useState(window.innerWidth < 991);

  return (
    <nav className="pcoded-navbar" style={{ marginLeft: !isMobile ? 0 : isOpen && isMobile ? 0 : -264 }}>
      <div className="navbar-wrapper">
        <div className="navbar-brand header-logo">
          <a className="b-brand">
            <span className="b-title">
              {t('dashboard')}
              <br />
              <span style={{ fontSize: 12 }}>Loop Campaign</span>
            </span>
          </a>
        </div>
        <div className="navbar-content scroll-div">
          <ul className="navbar-content_links">
            <Accordion isOpenNav={isOpen!} onOpen={onOpen} />
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Sidebar;
