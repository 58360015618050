import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

const firebaseConfig = () => {
  return JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!);
};

export const app = initializeApp(firebaseConfig());
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
