import { ChangeEvent, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { ICountryDTO } from '../../../types';

interface IProps {
  countries: ICountryDTO[];
  onPhoneChange: (phone: string) => void;
  label?: string;
  id?: string;
  onCountryChange?: (phoneCode: string) => void;
  initialCode?: string;
}
export type Ref = { reset(): void };

export const PhoneInput = forwardRef<Ref, IProps>((props, ref) => {
  const { countries, onPhoneChange, label, id, onCountryChange, initialCode = countries[0].phone_code } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [phone, setPhone] = useState<string>('');
  const [phoneCode, setPhoneCode] = useState<string>(initialCode);

  useImperativeHandle(
    ref,
    () => ({
      /**
       * Reset phone input value
       */
      reset() {
        setPhone('');
      },
    }),
    []
  );

  const phoneChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setPhone(value);
    onPhoneChange(phoneCode + value);
  };

  const countryChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setPhoneCode(value);
    onCountryChange?.(value);
  };

  // const reset = () => {
  //   setPhone('');
  //   // setPhoneCode(initialCode);
  // };

  return (
    <div>
      {label ? (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      ) : null}
      <div className="row">
        <div className="col-auto">
          {countries.length > 0 ? (
            <select className={'form-select'} onChange={countryChangeHandler} value={phoneCode}>
              {countries.map((country, index) => (
                <option key={country.name + index} value={country.phone_code}>
                  {`${country.name} (+${country.phone_code})`}
                </option>
              ))}
            </select>
          ) : null}
        </div>
        <div className="col">
          <div className="input-group">
            <span className="input-group-text">{'+' + phoneCode}</span>
            <input
              ref={inputRef}
              type="tel"
              id={id}
              className="form-control"
              onChange={phoneChangeHandler}
              value={phone}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
