import { FormEvent, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten, safeParse as valibotSafeParse } from 'valibot';

import { IOrganizationDetails } from '../../../types';
// import { COUNTRY_CODES } from '../../../consts';
import { OrganizationDetailsFormSchema } from '../../../schemes';
import { getUnifiedFormData } from '../../../lib/getUnifiedFormData';
import { FormElementError } from '../../common';

interface IProps {
  defaultValues?: Partial<IOrganizationDetails> | null;
  onSuccessSubmit?: (data: any) => void;
  showSubmitBtn?: boolean;
}
type Ref = HTMLFormElement;

export const OrganizationDetailsForm = forwardRef<Ref, IProps>((props, ref) => {
  const { defaultValues, onSuccessSubmit, showSubmitBtn = true } = props;
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<any>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const parseFormData = getUnifiedFormData(target);

    // console.log('parseFormData', parseFormData);

    const validate = valibotSafeParse(OrganizationDetailsFormSchema, parseFormData);

    // console.log('validate', validate);

    if (validate.success) {
      setFormErrors(undefined);

      if (onSuccessSubmit) {
        onSuccessSubmit(validate.output);
      }
      //   try {
      //     if (!token) return;
      //     setOrgDefaultValuesLoading(true);
      //     const res = await updateOrganizationDetailsAPI(companyId!, token, { default_values: validate.output });
      //     setOrgDefaultValuesSuccessSaved(true);
      //     setTimeout(() => {
      //       setOrgDefaultValuesSuccessSaved(false);
      //     }, 3000);
      //     if (res.code) {
      //       setIsPopup(true);
      //       setErrorText(`${res.message}. Error code: ${res.code}`);
      //     }
      //   } catch (error) {
      //     setError(error as Error);
      //     console.error(error);
      //   } finally {
      //     setOrgDefaultValuesLoading(false);
      //   }
    } else {
      const flattenErrors = flatten<typeof OrganizationDetailsFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
      // console.log('formErrors', flattenErrors.nested);
    }
  };

  return (
    <form id="organization-details-form" ref={ref} onSubmit={submitHandler}>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('name')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.name?.length ? ' is-invalid' : '')}
                  name="name"
                  placeholder={t('name')}
                  defaultValue={defaultValues?.name}
                />
                <small className="form-text text-muted">{t('orgNameHintText')}</small>
                {formErrors?.name?.length ? <FormElementError text={formErrors.name[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('website')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.website?.length ? ' is-invalid' : '')}
                  name="website"
                  placeholder="https://example.com"
                  defaultValue={defaultValues?.website}
                />
                <small className="form-text text-muted">{t('orgWebsiteHintText')}</small>
                {formErrors?.website?.length ? <FormElementError text={formErrors.website[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('supportPage')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.support_url?.length ? ' is-invalid' : '')}
                  name="support_url"
                  placeholder="https://example.com"
                  defaultValue={defaultValues?.supportPage}
                />
                <small className="form-text text-muted">{t('orgSupportPageHintText')}</small>
                {formErrors?.support_url?.length ? <FormElementError text={formErrors.support_url[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label" htmlFor="country-select">
                  {t('country')}
                </label>
                {defaultValues?.countries && defaultValues?.countries.length > 0 ? (
                  <select
                    id="country-select"
                    className={'form-control' + (formErrors?.country?.length ? ' is-invalid' : '')}
                    name="country"
                    defaultValue={defaultValues?.country}
                  >
                    {defaultValues.countries.map(({ name, code }, idx) => (
                      <option key={idx} value={code}>
                        {name}
                      </option>
                    ))}
                    {/* {Object.entries(COUNTRY_CODES).map(([k, v], idx) => (
                      <option key={idx} value={v}>
                        {k}
                      </option>
                    ))} */}
                  </select>
                ) : null}
                {formErrors?.country?.length ? <FormElementError text={formErrors.country[0] || ''} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSubmitBtn ? (
        <button className="btn btn-primary mt-2" type="submit" disabled={isFormSubmitting}>
          {isFormSubmitting ? t('sending') : t('save')}
        </button>
      ) : null}
    </form>
  );
});
