/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import Pagination from './common/Pagination';
import 'react-datepicker/dist/react-datepicker.css';
import CampaignsTable from './common/CampaignsTable';
import { campaignsAPIGetCampaignsData, campaignsAPIGetCampaignsFilterData } from '../../api';
import FilterBlock from './common/FilterBlock';
import MobileCampaignsTable from './common/MobileCampaignsTable';
import Spinner from './common/Spinner';
import ErrorPopup from '../../components/errors/ErrorPopup';

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);
  return debouncedValue;
}

const Campaigns: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();

  const [data, setData] = useState({
    count: 50,
    items: [],
    num_pages: 10,
    page: 1,
    per_page: 20,
  });

  const [filterQuery, setFilterQuery] = useState({
    page: data.page,
    per_page: data.per_page,
    sort_by: 'desc',
  });

  const [error, setError] = useState<Error | null>(null);

  const filtersOnChange = useCallback(() => {
    (async () => {
      try {
        if (token) {
          const res = await campaignsAPIGetCampaignsFilterData(token, filterQuery);
          if (res.code) {
            setIsPopup(true);
            setErrorText(`${res.message}. Error code: ${res.code}`);
          } else if ((!res.success && res.status && res.status !== 200) || !res) {
            setIsPopup(true);
            setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
          } else {
            setData(res);
            setLoading(true);
          }
        }
      } catch (e) {
        setError(e as Error);
        console.log(error);
      }
    })();
  }, [error, token, filterQuery]);

  useEffect(() => {
    //onData();
    setClearFilters(false);
  }, []);

  const [page, setPage] = useState(data.page);
  const nPages = data.num_pages;

  const [clearFilters, setClearFilters] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 1000);
  const [isMobile] = useState(window.innerWidth < 991);
  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');

  const filterQueryes = useCallback(
    (query: any) => {
      return Object.assign(filterQuery, query);
    },
    [filterQuery]
  );

  const deleteQueryes = useCallback(
    (query: any) => {
      for (const key in filterQuery) {
        if (key === query) {
          delete filterQuery[key as keyof typeof filterQuery];
        }
      }
    },
    [filterQuery]
  );

  const onEntries = useCallback(
    (value: string | number) => {
      filterQueryes({ per_page: value });
      setClearFilters(true);
      setEntriesValue(value as string);
      filtersOnChange();
      if (value === '20') {
        setClearFilters(false);
      }
    },
    [filterQueryes, filtersOnChange]
  );

  const onSearch = useCallback(
    (value: string) => {
      const search = { search: value };
      if (value === '') {
        deleteQueryes('search');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes(search);
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, setClearFilters, deleteQueryes]
  );

  useEffect(() => {
    if (debouncedSearch !== '') {
      onSearch(debouncedSearch);
    } else {
      onSearch('');
    }
  }, [debouncedSearch, onSearch, deleteQueryes, filtersOnChange, setClearFilters]);

  const [entriesValue, setEntriesValue] = useState('20');
  const [sortBy, setSortBy] = useState('desc');

  const onSort = useCallback(
    (value: string | number) => {
      setSortBy(value as string);
      filterQueryes({ sort_by: value });
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const onClearFilters = useCallback(() => {
    setSearchText('');
    setClearFilters(false);
    setFilterQuery({ page: 1, per_page: 20, sort_by: 'desc' });
    setPage(1);
    setEntriesValue('20');
    setShowDatePicker(false);
    setSortBy('desc');
    filtersOnChange();
  }, [filtersOnChange]);

  const onChangeData = useCallback(
    (c: boolean) => {
      if (c) {
        (async () => {
          try {
            if (token) {
              const res = await campaignsAPIGetCampaignsData(token);
              if (res.code) {
                setIsPopup(true);
                setErrorText(`${res.message}. Error code: ${res.code}`);
              } else if ((!res.success && res.status && res.status !== 200) || !res) {
                setIsPopup(true);
                setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
              } else {
                setData(res);
              }
            }
          } catch (e) {
            console.log(e);
          }
        })();
      }
    },
    [token]
  );

  const onPage = useCallback(
    (page: number) => {
      setPage(page);
      filterQueryes({ page });
      filtersOnChange();
    },
    [filtersOnChange, filterQueryes]
  );

  const [showDatePicker, setShowDatePicker] = useState(false);

  const onCalendar = useCallback(
    (show: boolean) => {
      setShowDatePicker(!show);
      deleteQueryes('from_date');
      deleteQueryes('to_date');
      filtersOnChange();
    },
    [deleteQueryes, filtersOnChange]
  );
  const popupClose = useCallback((c: boolean) => {
    setIsPopup(c);
  }, []);

  return (
    <div id="campaigns">
      <div>
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header-title">
              <h3 className="m-b-10">{t('campaigns')}</h3>
            </div>
            {!loading ? <Spinner /> : null}
            {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}
            <div className="card">
              <div className="card-block adaptive-table">
                <div className="row m-0 justify-content-between align-items-center">
                  <FilterBlock
                    filterQuery={filterQuery}
                    filtersOnChange={filtersOnChange}
                    setClearFilters={setClearFilters}
                    onEntries={onEntries}
                    entriesValue={entriesValue}
                    data={data.items}
                    converterName="campaigns"
                    onCalendar={onCalendar}
                    showPicker={showDatePicker}
                    onSort={onSort}
                    sortBy={sortBy}
                  />
                </div>
                <div className="row m-0 justify-content-between">
                  <div className="col-auto col-xl-auto offset-0 mb-4 p-0">
                    <div className="row m-0 align-items-center no-gutters m-r-5">
                      <div className="col-auto">
                        <p className="mb-0 mr-2">{t('search')}</p>
                      </div>
                      <div className="col">
                        <input
                          name="search"
                          onChange={(e) => setSearchText(e.target.value)}
                          className="form-control"
                          placeholder={t('searchInputTest')}
                          value={searchText}
                        />
                      </div>
                    </div>
                  </div>
                  {clearFilters ? (
                    <div className="d-flex mb-4">
                      <div
                        className="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center"
                        onClick={onClearFilters}
                      >
                        <div className="d-flex justify-content-center align-items-center}">Clear filters?</div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {data.items.length === 0 ? (
                  <p className="text-center">{t('noResults')}</p>
                ) : (
                  <>
                    {loading ? (
                      isMobile ? (
                        <MobileCampaignsTable data={data.items} onChange={onChangeData} />
                      ) : (
                        <CampaignsTable data={data.items} onChange={onChangeData} />
                      )
                    ) : null}
                    {loading ? <Pagination total={nPages} page={page} onChange={onPage} /> : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Campaigns;
