import { FormEvent, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten, safeParse as valibotSafeParse } from 'valibot';

import { getUnifiedFormData } from '../../../lib/getUnifiedFormData';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import { IVCard } from '../../../types';
import { VCardFormSchema } from '../../../schemes';
import { COUNTRY_CODES } from '../../../consts';
import { updateOrganizationDetailsAPI } from '../../../api';
import { FormElementError } from '../../common';
import Toast from '../../toasts/Toast';
import { ImageUploader } from './ImageUploader/ImageUploader';

import 'react-datepicker/dist/react-datepicker.css';

interface IProps {
  organizationId: string;
  defaultValues?: Partial<IVCard> | null;
  onSuccessSubmit?: (data: any) => void;
  onFailureSubmit?: (response: any) => void;
  showSubmitBtn?: boolean;
  disableSubmit?: boolean;
}

type Ref = HTMLFormElement;

export const VCardForm = forwardRef<Ref, IProps>((props, ref) => {
  const {
    defaultValues,
    onSuccessSubmit,
    onFailureSubmit,
    showSubmitBtn = true,
    disableSubmit = false,
    organizationId,
  } = props;
  const { token } = useAuthContext();
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<any>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false);
  const [isOrganization, setIsOrganization] = useState<boolean>(true);
  const [isNeedAddAddress, setIsNeedAddAddress] = useState<boolean>(false);

  const organizationCheckboxChange = () => {
    setIsOrganization((prev) => !prev);
  };

  const needAddAddressCheckboxChange = () => {
    setIsNeedAddAddress((prev) => !prev);
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const parseFormData = getUnifiedFormData(target);

    // console.log('parseFormData', parseFormData);

    const validate = valibotSafeParse(VCardFormSchema, parseFormData);

    // console.log('validate', validate);

    if (validate.success) {
      setFormErrors(undefined);

      if (onSuccessSubmit) {
        onSuccessSubmit({ vcard: validate.output });
      }

      if (!disableSubmit) {
        try {
          if (!token || !organizationId) {
            console.error('You miss token or organizationId');
            return;
          }

          setIsFormSubmitting(true);

          const res = await updateOrganizationDetailsAPI(organizationId, token, { vcard: validate.output });

          if (res.code) {
            onFailureSubmit && onFailureSubmit(res);
          }

          setIsFormSuccess(true);
          setTimeout(() => {
            setIsFormSuccess(false);
          }, 3000);
        } catch (error) {
          console.error(error);
        } finally {
          setIsFormSubmitting(false);
        }
      }
    } else {
      const flattenErrors = flatten<typeof VCardFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
      // console.log('formErrors', flattenErrors.nested);
    }
  };

  return (
    <form id="vcard-form" ref={ref} onSubmit={submitHandler}>
      <div className="alert alert-info" role="alert">
        {t('vcard.alertInfoText')}.
      </div>
      <div className="mb-3">
        <div className="form-check">
          <input
            id="organization-checkbox"
            className="form-check-input input-primary"
            type="checkbox"
            name="is_organization"
            checked={isOrganization}
            onChange={organizationCheckboxChange}
          />
          <label className="form-check-label" htmlFor="organization-checkbox">
            {t('vcard.forms.isOrganization')}
          </label>
        </div>
      </div>
      <div className="row">
        {isOrganization ? (
          <div className="col-12">
            <div className="mb-3">
              <label className="form-label">{t('vcard.forms.organizationName')}:</label>
              <input
                type="text"
                className={'form-control' + (formErrors?.organization_name?.length ? ' is-invalid' : '')}
                name="organization_name"
                placeholder="Enter the name"
                defaultValue={defaultValues?.organizationName}
              />
              {formErrors?.organization_name?.length ? (
                <FormElementError text={formErrors.organization_name[0] || ''} />
              ) : null}
            </div>
          </div>
        ) : (
          <>
            <div className="col-12 col-lg-4">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.firstName')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.first_name?.length ? ' is-invalid' : '')}
                  name="first_name"
                  placeholder="Enter first name"
                  defaultValue={defaultValues?.firstName}
                />
                {formErrors?.first_name?.length ? <FormElementError text={formErrors.first_name[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.middleName')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.middle_name?.length ? ' is-invalid' : '')}
                  name="middle_name"
                  placeholder="Enter middle name"
                  defaultValue={defaultValues?.middleName}
                />
                {formErrors?.middle_name?.length ? <FormElementError text={formErrors.middle_name[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.lastName')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.last_name?.length ? ' is-invalid' : '')}
                  name="last_name"
                  placeholder="Enter last name"
                  defaultValue={defaultValues?.lastName}
                />
                {formErrors?.last_name?.length ? <FormElementError text={formErrors.last_name[0] || ''} /> : null}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <ImageUploader
              organizationId={organizationId}
              initialValue={defaultValues?.photoUrl}
              formErrors={formErrors}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.websiteUrl')}:</label>
            <input
              type="text"
              className={'form-control' + (formErrors?.website_url?.length ? ' is-invalid' : '')}
              name="website_url"
              placeholder="https://example.com/"
              defaultValue={defaultValues?.websiteUrl}
            />
            {formErrors?.website_url?.length ? <FormElementError text={formErrors.website_url[0] || ''} /> : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.email')}:</label>
            <input
              type="email"
              className={'form-control' + (formErrors?.email?.length ? ' is-invalid' : '')}
              name="email"
              placeholder="example@mail.com"
              defaultValue={defaultValues?.email}
            />
            {formErrors?.email?.length ? <FormElementError text={formErrors.email[0] || ''} /> : null}
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.phoneNumber')}:</label>
            <input
              type="tel"
              className={'form-control' + (formErrors?.phone_number?.length ? ' is-invalid' : '')}
              name="phone_number"
              placeholder="Enter the phone number"
              defaultValue={defaultValues?.phoneNumber}
            />
            {formErrors?.phone_number?.length ? <FormElementError text={formErrors.phone_number[0] || ''} /> : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.birthday')}:</label>
            <input
              type="date"
              className={'form-control' + (formErrors?.birthday?.length ? ' is-invalid' : '')}
              name="birthday"
              defaultValue={defaultValues?.birthday}
            />
            {formErrors?.birthday?.length ? <FormElementError text={formErrors.birthday[0] || ''} /> : null}
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.supportPage')}:</label>
            <input
              type="text"
              className={'form-control' + (formErrors?.support_url?.length ? ' is-invalid' : '')}
              name="support_url"
              placeholder="https://example.com/"
              defaultValue={defaultValues?.supportUrl}
            />
            {formErrors?.support_url?.length ? <FormElementError text={formErrors.support_url[0] || ''} /> : null}
          </div>
        </div>
      </div>

      <hr className="my-4" />

      <div className="mb-3">
        {/* <label className="form-label">language:</label> */}
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input input-primary"
            id="add-address-checkbox"
            name="need_add_address"
            checked={isNeedAddAddress}
            onChange={needAddAddressCheckboxChange}
          />
          <label className="form-check-label" htmlFor="add-address-checkbox">
            {t('vcard.forms.needAddAddress')}
          </label>
        </div>
      </div>
      {isNeedAddAddress ? (
        <>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.addressName')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.address_name?.length ? ' is-invalid' : '')}
                  name="address_name"
                  placeholder="Enter the address"
                  defaultValue={defaultValues?.addressName}
                />
                {formErrors?.address_name?.length ? <FormElementError text={formErrors.address_name[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.street')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.street?.length ? ' is-invalid' : '')}
                  name="street"
                  placeholder="Enter the street"
                  defaultValue={defaultValues?.street}
                />
                {formErrors?.street?.length ? <FormElementError text={formErrors.street[0] || ''} /> : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.city')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.city?.length ? ' is-invalid' : '')}
                  name="city"
                  placeholder="Enter the city"
                  defaultValue={defaultValues?.city}
                />
                {formErrors?.city?.length ? <FormElementError text={formErrors.city[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.state')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.state?.length ? ' is-invalid' : '')}
                  name="state"
                  placeholder="Enter the state or province"
                  defaultValue={defaultValues?.state}
                />
                {formErrors?.state?.length ? <FormElementError text={formErrors.state[0] || ''} /> : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('vcard.forms.postalCode')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.postal_code?.length ? ' is-invalid' : '')}
                  name="postal_code"
                  placeholder="Enter the postal code"
                  defaultValue={defaultValues?.postalCode}
                />
                {formErrors?.postal_code?.length ? <FormElementError text={formErrors.postal_code[0] || ''} /> : null}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label" htmlFor="country-select">
                  {t('vcard.forms.country')}
                </label>
                <select
                  id="country-select"
                  className={'form-control' + (formErrors?.country?.length ? ' is-invalid' : '')}
                  name="country"
                  defaultValue={defaultValues?.country}
                >
                  {Object.entries(COUNTRY_CODES).map(([k, v], idx) => (
                    <option key={idx} value={v}>
                      {k}
                    </option>
                  ))}
                </select>
                {formErrors?.country?.length ? <FormElementError text={formErrors.country[0] || ''} /> : null}
              </div>
            </div>
          </div>
        </>
      ) : null}

      <hr className="my-4" />

      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.facebookUrl')}:</label>
            <input
              type="text"
              className={'form-control' + (formErrors?.facebook_url?.length ? ' is-invalid' : '')}
              name="facebook_url"
              placeholder="https://facebook.com/{name}"
              defaultValue={defaultValues?.facebookUrl}
            />
            {formErrors?.facebook_url?.length ? <FormElementError text={formErrors.facebook_url[0] || ''} /> : null}
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.instagramUrl')}:</label>
            <input
              type="text"
              className={'form-control' + (formErrors?.instagram_url?.length ? ' is-invalid' : '')}
              name="instagram_url"
              placeholder="https://instagram.com/{name}"
              defaultValue={defaultValues?.instagramUrl}
            />
            {formErrors?.instagram_url?.length ? <FormElementError text={formErrors.instagram_url[0] || ''} /> : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.twitterUrl')}:</label>
            <input
              type="text"
              className={'form-control' + (formErrors?.twitter_url?.length ? ' is-invalid' : '')}
              name="twitter_url"
              placeholder="https://twitter.com/{name}"
              defaultValue={defaultValues?.twitterUrl}
            />
            {formErrors?.twitter_url?.length ? <FormElementError text={formErrors.twitter_url[0] || ''} /> : null}
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.linkedinUrl')}:</label>
            <input
              type="text"
              className={'form-control' + (formErrors?.linkedin_url?.length ? ' is-invalid' : '')}
              name="linkedin_url"
              placeholder="https://linkedin.com/in/{name}"
              defaultValue={defaultValues?.linkedinUrl}
            />
            {formErrors?.linkedin_url?.length ? <FormElementError text={formErrors.linkedin_url[0] || ''} /> : null}
          </div>
        </div>
      </div>

      <hr className="my-4" />

      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label className="form-label">{t('vcard.forms.note')}:</label>
            <textarea
              className={'form-control' + (formErrors?.note?.length ? ' is-invalid' : '')}
              name="note"
              placeholder="Enter the note"
              defaultValue={defaultValues?.note}
              rows={3}
            />
            {formErrors?.note?.length ? <FormElementError text={formErrors.note[0] || ''} /> : null}
          </div>
        </div>
        {defaultValues?.noteHeader ? (
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <label className="form-label">{t('vcard.forms.noteHeader')}:</label>
              <textarea
                className={'form-control' + (formErrors?.note_header?.length ? ' is-invalid' : '')}
                name="note_header"
                placeholder="Enter the note header"
                defaultValue={defaultValues?.noteHeader}
                rows={3}
                disabled
              />
              <small className="form-text text-muted">{t('changeFieldHintText')}.</small>
              {formErrors?.note_header?.length ? <FormElementError text={formErrors.note_header[0] || ''} /> : null}
            </div>
          </div>
        ) : null}
      </div>
      <div className="row">
        {defaultValues?.noteFooter ? (
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <label className="form-label">{t('vcard.forms.noteFooter')}:</label>
              <textarea
                className={'form-control' + (formErrors?.note_footer?.length ? ' is-invalid' : '')}
                name="note_footer"
                placeholder="Enter the note footer"
                defaultValue={defaultValues?.noteFooter}
                rows={3}
                disabled
              />
              <small className="form-text text-muted">{t('changeFieldHintText')}.</small>
              {formErrors?.note_footer?.length ? <FormElementError text={formErrors.note_footer[0] || ''} /> : null}
            </div>
          </div>
        ) : null}
      </div>

      {showSubmitBtn ? (
        <button className="btn btn-primary mt-2" type="submit" disabled={isFormSubmitting}>
          {isFormSubmitting ? t('sending') : t('save')}
        </button>
      ) : null}

      {isFormSuccess ? (
        <div className="mt-4">
          <Toast message={t('vcard.saved') + '!'} />
        </div>
      ) : null}
    </form>
  );
});
