import React from 'react';
import '../../index.css';

type AuthLayoutProps = {
  children: React.ReactNode;
  signUp?: boolean;
};
const AuthLayout: React.FC<AuthLayoutProps> = ({ children, signUp }) => {
  return (
    <div
      className="auth-main auth-bg-img"
      data-pc-preset="preset-1"
      data-pc-sidebar-caption="true"
      data-pc-direction="ltr"
      data-pc-theme="light"
    >
      <div className="auth-wrapper v5">
        <div className="auth-form">
          <div className={`${signUp && 'card-signup'} card my-5`}>
            <div className="card-body">
              <div className="text-center m-t-5">
                <a href="#">
                  <img style={{ height: 32, width: 32 }} src="/assets/images/auth/unlock.svg" />
                </a>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;
