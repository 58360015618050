import { getDownloadURL, StorageReference, uploadBytes } from 'firebase/storage';

export const uploadToFirebaseStorage = async (storageRef: StorageReference, file: File): Promise<string> => {
  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Storage error', error);
    throw error;
  }
};
