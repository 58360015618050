interface IValidateUrlReturn {
  success: boolean;
  message?: string;
}

function validateUrl(url: string): IValidateUrlReturn {
  const error = {
    success: false,
    message: '',
  };

  if (!url) {
    error.message = 'URL is required';
    return error;
  }

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    error.message = 'Invalid URL. URL must starts with https:// or http://';
    return error;
  }

  if (!/\.\w*\d*$/.test(url)) {
    error.message = 'The URL must end with a file format. For example: .pdf, .doc, .txt';
    return error;
  }

  return { success: true };
}

export interface ICreateThumbnailFromUrlReturn {
  type: 'file';
  name: string;
  originalUrl: string;
  blobUrl: string;
  size: number | null;
}

export const createThumbnailFromUrl = (url: string | URL): Promise<ICreateThumbnailFromUrlReturn> => {
  return new Promise((resolve, reject) => {
    const _url = new URL(url);
    const fileUrl = _url.origin + _url.pathname;
    const validate = validateUrl(fileUrl);
    if (!validate.success) {
      reject(new Error(validate.message));
      return;
    }

    fetch(fileUrl, { mode: 'no-cors', method: 'HEAD' })
      .then((response) => {
        const contentLength = response.headers.get('content-length');
        console.log(contentLength);
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        resolve({
          type: 'file',
          name: _url.pathname.split(/\//g).at(-1)! || 'NoName',
          originalUrl: fileUrl,
          blobUrl,
          size: blob.size || null,
        });
      })
      .catch(() => {
        reject(new Error('Failed to load file'));
      });
  });
};
