/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchTokenExpired = (response: any): any => {
  if (response.code === 120) {
    window.dispatchEvent(new CustomEvent('invalidToken'));
    throw new Error('Unautharized');
  }

  return response;
};

export const handleUnathorizedRequest = () => {
  localStorage.clear();
  window.location.href = '/';
};

export const handleErrorStatusRequest = (status: number) => {
  return { error: true, success: false, status: status };
};

export const errorsHandler = (response: any): any => {
  const res = (async () => {
    try {
      if (response.code === 120) {
        window.dispatchEvent(new CustomEvent('invalidToken'));
        throw new Error('Unautharized');
      }

      if (response.status === 403) {
        handleUnathorizedRequest();
      }
      if (response.status !== 200 && response.status !== 403 && response.status !== 400) {
        return handleErrorStatusRequest(response.status);
      }
      if (response.status === 400) {
        const res = await response.json();
        return res;
      }
      const res = await response.json();
      return res;
    } catch (e) {
      console.log(e);
    }
  })();
  return res;
};
