interface IValidateUrlReturn {
  success: boolean;
  message?: string;
}

function validateImgUrl(url: string): IValidateUrlReturn {
  const error = {
    success: false,
    message: '',
  };

  if (!url) {
    error.message = 'URL is required';
    return error;
  }

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    error.message = 'Invalid URL. URL must starts with https:// or http://';
    return error;
  }

  if (
    !url.endsWith('.jpg') &&
    !url.endsWith('.jpeg') &&
    !url.endsWith('.png') &&
    !url.endsWith('.gif') &&
    !url.endsWith('.svg') &&
    !url.endsWith('.webp') &&
    !url.endsWith('.avif')
  ) {
    error.message = 'You placed an unsupported format. The valid formats: .jpg, .jpeg, .png, .gif, .svg, .webp, .avif';
    return error;
  }

  return { success: true };
}

export interface ICreateThumbnailFromImgUrlReturn {
  type: 'image';
  name: string;
  originalUrl: string;
  blobUrl: string;
  width: number;
  height: number;
}

export const createThumbnailFromImgUrl = (url: string | URL): Promise<ICreateThumbnailFromImgUrlReturn> => {
  return new Promise((resolve, reject) => {
    const _url = new URL(url);
    const imgUrl = _url.origin + _url.pathname;
    const validate = validateImgUrl(imgUrl);
    if (!validate.success) {
      reject(new Error(validate.message));
      return;
    }

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = imgUrl;
    img.onload = (e) => {
      console.log(e);
      const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
      const canvas = document.createElement('canvas');
      canvas.width = naturalWidth;
      canvas.height = naturalHeight;
      const ctx = canvas.getContext('2d');
      ctx!.drawImage(img, 0, 0, naturalWidth, naturalHeight);
      canvas.toBlob((b) => {
        const blobUrl = URL.createObjectURL(b!);
        resolve({
          type: 'image',
          name: _url.pathname.split(/\//g).at(-1)! || 'NoName',
          originalUrl: imgUrl,
          blobUrl,
          width: naturalWidth,
          height: naturalHeight,
        });
      }, 'image/jpeg');
    };
    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
};
