import React, { useCallback, useState } from 'react';
import Sidebar from '../../components/dashboard/Sidebar';
import DasboardPageHeader from './DasboardPageHeader';

type DashboardLayoytProps = {
  children: React.ReactNode;
};

const DashboardLayoyt: React.FC<DashboardLayoytProps> = ({ children }) => {
  const email = localStorage.getItem('userEmail');

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback((c: boolean) => {
    setIsOpen(c);
  }, []);

  return (
    <div id="dashboard-layout">
      <Sidebar isOpen={isOpen} onOpen={onOpen} />
      <DasboardPageHeader email={email as string} onOpen={onOpen} isOpen={isOpen} />
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper limitWidth">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">{children}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default DashboardLayoyt;
