/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import ReactDatePicker from 'react-datepicker';
import CommonSelect from './CommonSelect';
import Csvonverter from './Csvonverter';
import { DateTime } from 'luxon';
import 'react-datepicker/dist/react-datepicker.css';

interface FilterBlockProps {
  filtersOnChange: () => void;
  setClearFilters: (clear: boolean) => void;
  onEntries: (value: string | number) => void;
  onSort: (value: string | number) => void;
  onCalendar: (show: boolean) => void;
  filterQuery: any;
  data: any;
  converterName: string;
  entriesValue?: string;
  sortBy?: string;
  showPicker?: boolean;
}

const FilterBlock: React.FC<FilterBlockProps> = ({
  filtersOnChange,
  setClearFilters,
  filterQuery,
  onEntries,
  data,
  converterName,
  entriesValue,
  sortBy,
  onSort,
  onCalendar,
  showPicker,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  const onShowCalendar = () => {
    setShowDatePicker(!showDatePicker);
    onCalendar(showDatePicker);
  };

  const filterQueryes = useCallback(
    (query: any) => {
      return Object.assign(filterQuery, query);
    },
    [filterQuery]
  );

  const { t } = useTranslation();

  const onStartDate = useCallback(
    (value: Date) => {
      setStartDate(value);
      const startDate = { from_date: DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') };
      filterQueryes(startDate);
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const onEndDate = useCallback(
    (value: Date) => {
      setEndDate(value);
      const endDate = { to_date: DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') };
      filterQueryes(endDate);
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const sortOptions = useMemo(
    () => [
      { title: t('latestFirst'), value: 'desc' },
      { title: t('oldestFirst'), value: 'asc' },
    ],
    [t]
  );

  return (
    <div className="col-12 p-0  col-xl">
      <div className="row m-0 justify-content-between align-items-center">
        <div className="col-12 p-0  col-sm-auto offset-0 mb-4">
          <div className="row m-0 align-items-center no-gutters">
            <div className="col-auto">
              <p className="mb-0 mr-2">{t('show')}</p>
            </div>
            <CommonSelect
              name="entries"
              onChange={onEntries}
              options={[
                { value: 20, title: 20 },
                { value: 50, title: 50 },
                { value: 100, title: 100 },
                { value: 200, title: 200 },
                { value: 500, title: 500 },
                { value: 1000, title: 1000 },
                { value: 2000, title: 2000 },
                { value: 5000, title: 5000 },
              ]}
              startValue={entriesValue}
              isStatic
            />
            <div className="offset-0 ml-2">
              <p className="mb-0">{t('entries')}</p>
            </div>
          </div>
        </div>
        <div className="col-12 p-0  col-sm-7 col-lg-4 offset-0 mb-4 d-flex">
          <div className="row m-0 align-items-center no-gutters" style={{ flexShrink: 0, minWidth: 305 }}>
            <div className="col-auto">
              <p className="mb-0 mr-2">{t('date')}</p>
            </div>
            <div className="col offset-0">
              <div className="inline-block">
                <div className="form-control datepicker">
                  <div onClick={onShowCalendar} style={{ cursor: 'pointer' }}>
                    <FeatherIcon className="feather m-r-5 m-t-3m" icon="calendar" size={16} />
                  </div>
                  {showPicker ? (
                    <>
                      <ReactDatePicker
                        wrapperClassName="input"
                        selected={startDate as Date}
                        onChange={(date: Date) => onStartDate(date)}
                        maxDate={new Date()}
                        dateFormat="yyyy/dd/MM"
                      />
                      <ReactDatePicker
                        wrapperClassName="input-right"
                        selected={endDate as Date}
                        onChange={(date) => {
                          onEndDate(date as Date);
                        }}
                        maxDate={new Date()}
                        dateFormat="yyyy/dd/MM"
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 p-0  col-sm-auto offset-0 mb-4">
          <div className="row m-0 align-items-center no-gutters">
            <div className="col-auto">
              <p className="mb-0 mr-2">{t('sortBy')}</p>
            </div>
            <div className="col-auto offset-0">
              <CommonSelect
                name="sort"
                onChange={onSort}
                options={sortOptions}
                isStatic
                startValue={sortBy}
              />
            </div>
          </div>
        </div>
        <Csvonverter fileName={converterName} items={data} />
      </div>
    </div>
  );
};
export default FilterBlock;
