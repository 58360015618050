/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../index.css';
import RegistrationAlert from '../../components/errors/RegistrationAlert';

type LoginCodeLayoutProps = {
  banner?: boolean;
  loading: boolean;
  handleCode: (c: string) => void;
};

const LoginCodeLayout: React.FC<LoginCodeLayoutProps> = ({ banner, handleCode, loading }) => {
  const { t } = useTranslation();

  const [validCode, setValidCode] = useState(false);
  const [hide, setHide] = useState(true);

  const onChangeCode = useCallback(
    (event: any) => {
      setHide(false);
      const value = event.target.value;
      if (value.length >= 4 && value.length <= 6) {
        setValidCode(true);
        const newCode = value.trim();

        handleCode(newCode);
      } else {
        setValidCode(false);
      }
    },
    [handleCode]
  );

  return (
    <>
      <label htmlFor="floatingInput" className="m-b-5">
        {t('code')}
      </label>

      <input
        maxLength={6}
        minLength={4}
        type="text"
        className={`form-control ${!validCode && !hide && 'invalid'} `}
        id="floatingInput"
        onChange={onChangeCode}
      />
      {(!validCode && !hide) || banner ? (
        <RegistrationAlert banner={banner} type="otp" text={t('forms.signUp.errors.1087')} />
      ) : null}

      <div className="text-center m-t-25 m-b-15">
        <button type="submit" className="btn btn-primary shadow px-sm-4" disabled={!validCode || loading}>
          {t('logIn')}
        </button>
      </div>
    </>
  );
};
export default LoginCodeLayout;
