import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ref as firebaseRef } from 'firebase/storage';

import { ICampaign } from '../../../../types';
import { storage } from '../../../../config/firebase';
import { isImage } from '../../../../lib/isImage';
import { getImageDimensions } from '../../../../lib/getImageDimensions';
import { uploadToFirebaseStorage } from '../../../../lib/firebase/uploadToFirebaseStorage';
import { deleteFromFirebaseStorage } from '../../../../lib/firebase/deleteFromFirebaseStorage';
import { createThumbObjectFromUrl } from '../../../../lib/createThumbObjectFromUrl';
import { FileUploader, FormElementError, UrlUploader, UploadedFilePreview } from '../../../../components/common';
import { ICreateThumbnailFromImgUrlReturn } from '../../../../lib/createThumbnailFromImgUrl';
import { ICreateThumbnailFromUrlReturn } from '../../../../lib/createThumbnailFromUrl';

interface IProps {
  organizationId: string;
  campaignId: string;
  className?: string;
  formErrors?: any;
  initialValue?: ICampaign['attachments'];
  onAttachmentsChange?: (urls: string[]) => void;
}
export const CampaignUploader: FC<IProps> = (props) => {
  const { organizationId, campaignId, formErrors: errors, initialValue, onAttachmentsChange } = props;
  const { t } = useTranslation();

  const thumbnailsRef = useRef<HTMLDivElement>(null);

  const [formErrors, setFormErrors] = useState<any>(errors);
  const [thumbnails, setThumbnails] = useState<any[]>(
    initialValue?.length ? initialValue.map((attachment) => createThumbObjectFromUrl(attachment)) : []
  );
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(initialValue ?? []);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

  const addUrlHandler = (thumb: ICreateThumbnailFromImgUrlReturn | ICreateThumbnailFromUrlReturn) => {
    setThumbnails((prev) => [...prev, thumb]);
    setUploadedFiles((prev) => [...prev, thumb.originalUrl]);
    onAttachmentsChange?.([...uploadedFiles, thumb.originalUrl]);
  };

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files?.length) {
      return;
    }

    setFormErrors(undefined);

    setIsLoadingFile(true);

    await Array.fromAsync(files, async (file) => {
      await uploadFile(file);
      await createThumbnail(file);
      return file;
    });

    setIsLoadingFile(false);

    async function uploadFile(file: File) {
      const validate = validateFile(file);
      if (!validate.success) {
        setFormErrors({
          attachments: [validate.message],
        });
        return;
      }

      const uploadedUrl = await uploadToFirebase(file);

      setUploadedFiles((prev) => [...prev, uploadedUrl]);

      onAttachmentsChange?.([...uploadedFiles, uploadedUrl]);
    }

    async function createThumbnail(file: File) {
      return new Promise((resolve, reject) => {
        const { name, size, type: fileType } = file;
        const reader = new FileReader();
        const validate = validateFile(file);
        if (!validate.success) {
          setFormErrors({
            attachments: [validate.message],
          });
          reject();
          return;
        }

        reader.onload = async (event) => {
          const result = event.target?.result as string;
          let width = null;
          let height = null;
          if (isImage(name)) {
            const dimensions = await getImageDimensions(result);
            width = dimensions.width;
            height = dimensions.height;
          }
          const thumbObj = {
            type: isImage(name) ? 'image' : 'file',
            name,
            size: Math.round(size / 1024),
            width,
            height,
            dataUrl: result,
          };

          setThumbnails((prev) => [...prev, thumbObj]);

          resolve(result);
        };

        reader.readAsDataURL(file);
      });
    }
  };

  function validateFile(file: File): { success: boolean; message?: string } {
    const error = {
      success: false,
      message: '',
    };

    if (file.size / 1024 > +process.env.REACT_APP_FILE_SIZE_LIMIT!) {
      error.message = 'The file size limit is reached';
      return error;
    }

    return { success: true };
  }

  const uploadToFirebase = async (file: File): Promise<string> => {
    const fileName = `${organizationId}/campaign/${campaignId}/${Date.now()}-${file.name}`;
    const storageRef = firebaseRef(storage, fileName);
    return await uploadToFirebaseStorage(storageRef, file);
  };

  const deleteFileHandler = async (index: number): Promise<any> => {
    deleteFile(index);
    removeThumbnail(index);
  };

  const deleteFile = (index: number) => {
    if (!thumbnails[index]?.originalUrl) {
      deleteFromFirebaseStorage(firebaseRef(storage, uploadedFiles[index]));
    }

    setUploadedFiles(uploadedFiles.toSpliced(index, 1));
    onAttachmentsChange?.(uploadedFiles.toSpliced(index, 1));
  };

  const removeThumbnail = (index: number) => {
    const blobUrl = thumbnails[index].blobUrl;
    blobUrl && URL.revokeObjectURL(blobUrl);

    setThumbnails(thumbnails.toSpliced(index, 1));
  };

  return (
    <div>
      <input
        className={formErrors?.['attachments']?.length ? ' is-invalid' : ''}
        type="hidden"
        name="attachments"
        value={JSON.stringify(uploadedFiles)}
      />
      <label className="form-label">{t('attachments')}:</label>
      <UrlUploader onSuccess={(thumb) => addUrlHandler(thumb)} />
      <small className="form-text text-muted">
        {t('vcard.forms.photoUrlHelp', { size: +process.env.REACT_APP_FILE_SIZE_LIMIT! / 1024 })}
      </small>

      <span className="d-block text-center my-3">{t('or').toUpperCase()}</span>

      <FileUploader
        id="campaign-upload-btn"
        onUpload={(e) => uploadFileHandler(e)}
        isLoading={isLoadingFile}
        multiple
      />

      {formErrors?.['attachments']?.length ? <FormElementError text={formErrors['attachments'][0] || ''} /> : null}

      {thumbnails.length > 0 ? (
        <div ref={thumbnailsRef} className="thumbnails d-flex flex-column gap-2 mt-3">
          {thumbnails.map((file, idx) => (
            <UploadedFilePreview key={idx} file={file} onDelete={() => deleteFileHandler(idx)} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
