import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ICountryDTO, ITestMessage } from '../../types';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import { campaignsAPIPostTestMessage } from '../../api';
import Toast from '../toasts/Toast';
import { PhoneInput, type PhoneInputRef } from '../common';

interface IProps {
  data: ITestMessage;
  countries: ICountryDTO[];
}
export const SendTestMessage: FC<IProps> = (props) => {
  const { data, countries } = props;
  const { token } = useAuthContext();
  const { t } = useTranslation();

  const phoneRef = useRef<PhoneInputRef>(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryRegionCode, setCountryRegionCode] = useState('');
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const testMessageData = useMemo(() => {
    return {
      organization_id: data.organizationId,
      contact: phoneNumber,
      country_region: countryRegionCode,
      text: data.text,
      subject: data.subject,
      effect: data.effect ?? 'No Effect',
      attachments: data.attachments,
    };
  }, [phoneNumber, countryRegionCode, data.text, data.subject, data.effect, data.organizationId, data.attachments]);

  const phoneChangeHandler = (phone: string) => {
    setPhoneNumber(phone);
  };

  const countryChangeHandler = (phoneCode: string) => {
    const country = countries.find((country) => country.phone_code === phoneCode);
    setCountryRegionCode(country?.code ?? '');
  };

  const sendTestMessageHandler = () => {
    if (!phoneNumber || phoneNumber === '') {
      // setIsPopup(true);
      // setErrorText(t(`forms.checkout.errors.150`));
      return;
    }

    (async () => {
      if (!token) {
        console.error('You missed token');
        return;
      }

      setIsLoading(true);

      try {
        const res = await campaignsAPIPostTestMessage(token, testMessageData);
        if (res.code) {
          // setIsPopup(true);
          // setErrorText(`${res.message}. Error code: ${res.code}`);
          return;
        }

        if ((!res.success && res.status && res.status !== 200) || !res) {
          // setIsPopup(true);
          // setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
          return;
        }

        phoneRef?.current?.reset();

        setToast(true);
        setToastMessage('Request accepted');
        setTimeout(() => setToast(false), 5000);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>{t('sendTestMessage')}</h5>
      </div>
      <div className="card-block">
        <PhoneInput
          ref={phoneRef}
          label={t('phoneNumber')}
          countries={countries}
          onPhoneChange={phoneChangeHandler}
          onCountryChange={(phoneCode) => countryChangeHandler(phoneCode)}
        />
        <div className="d-flex mt-3">
          <button type="button" className="btn btn-primary" onClick={sendTestMessageHandler} disabled={isLoading}>
            {t('sendTestMessage')}
          </button>
        </div>

        {toast ? (
          <div className="mt-3">
            <Toast message={toastMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
