import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {LangDropdown} from '../../components/common';

const SomethingWrong: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className="pcoded-content">
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wreapper">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-md-9 col-xl-7">
                <div className="row justify-content-end">
                  <LangDropdown />
                </div>
                <h2>Oops!</h2>
                <h3 className="mb-20">{t('forms.submitMessage.errors.100')}</h3>
                <button className="btn btn-primary" onClick={onHome}>
                  {t('backToHome')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SomethingWrong;
