import type { FC } from 'react';
import type { IProps } from './Dropdown';

import { Dropdown as Root, Trigger, Menu, Item, Divider } from './Dropdown';

export const Dropdown: FC<IProps> & {
  Trigger: typeof Trigger;
  Menu: typeof Menu;
  Item: typeof Item;
  Divider: typeof Divider;
} = Object.assign(Root, {
  Trigger,
  Menu,
  Item,
  Divider,
});

Trigger.displayName = 'Dropdown.Trigger';
Menu.displayName = 'Dropdown.Menu';
Item.displayName = 'Dropdown.Item';
Divider.displayName = 'Dropdown.Divider';
