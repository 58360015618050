/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { authAPIPostSignIn } from '../../api';
import ErrorPopup from '../../components/errors/ErrorPopup';
import { LangDropdown } from '../../components/common';
import RegistrationAlert from '../../components/errors/RegistrationAlert';
import AuthLayout from '../../components/layout/AuthLayout';

const LoginEmail: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleSignUp = () => navigate('/sign-up');

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [hide, setHide] = useState(true);
  const [message, setMessage] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isPopup, setIsPopup] = useState(false);

  const emailAddress = useCallback(
    (event: any) => {
      const value = event.target.value;
      setMessage(false);
      const EMAIL_REGEXP = /^\S+@\S+\.\S+$/;
      if (EMAIL_REGEXP.test(value)) {
        setValidEmail(true);
        setEmail(value.trim());
      } else {
        setValidEmail(false);
        setEmail(value.trim());
      }
      if (!value || value === '') {
        setHide(true);
        setIsPopup(false);
        setMessage(false);
      }
    },
    [setHide]
  );

  const onRestorePassword = useCallback(() => {
    navigate('/login-email-password-restore');
  }, [navigate]);

  const onUpdateEmail = useCallback(() => {
    if (email != '') {
      setHide(true);
    }
    if (!validEmail && email != '') {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [validEmail, email]);

  const onInput = useCallback(
    (e: SyntheticEvent) => {
      (async () => {
        if (!validEmail) {
          setHide(false);
        } else {
          setHide(true);
        }
        if (!loading) {
          try {
            e.preventDefault();
            setLoading(true);
            if (validEmail) {
              localStorage.setItem('userEmail', email);
              const data = {
                contact: email,
              };

              const res = await authAPIPostSignIn(data);
              if (res.code === 140) {
                setIsPopup(true);
                setErrorText(t(`errors.wrongAccount`));
              }
              if (res.success) {
                // localStorage.setItem('user', JSON.stringify(res.user));

                if (res.authorization_type === 1) {
                  navigate('/login-code-sign-in', {
                    state: {
                      email,
                    },
                  });
                } else if (res.authorization_type === 2) {
                  navigate('/login-password', {
                    state: {
                      email,
                    },
                  });
                }
              }

              if (!res.success && res.code === 140) {
                setMessage(true);
              }
            } else {
              console.log('Validation failed');
            }
          } catch (e) {
            setError(e as Error);
            console.log(error);
          } finally {
            setLoading(false);
          }
        }
      })();
    },
    [validEmail, email, navigate, error, loading, t]
  );

  return (
    <>
      {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} /> : null}
      <AuthLayout>
        <h4 className="text-center f-w-500 m-t-25">{t('Login')}</h4>
        <form onSubmit={onInput} className="form-group mb-3 t-a-l">
          <label htmlFor="floatingInput" className="m-b-5">
            {t('signinMessage')}
          </label>
          <input
            type="email"
            className={`form-control ${!validEmail && !hide && 'invalid'} `}
            id="floatingInput"
            onChange={emailAddress}
            required
            onBlur={onUpdateEmail}
            autoFocus
          />
          {!validEmail && !hide ? <RegistrationAlert text={t('errors.general.550')} /> : null}
          {message ? <RegistrationAlert text={t('errors.wrongAccount')} /> : null}
          <div className="text-center m-t-25 m-b-15">
            <button
              type="submit"
              className="btn btn-primary shadow px-sm-4"
              disabled={!validEmail || message || loading}
            >
              {t('continue')}
            </button>
          </div>
        </form>
        <div className="d-flex justify-content-center align-items-center m-b-25">
          <p className="f-w-500 text-muted f-13 m-b-0">{t('signupProposal')}</p>
          <span onClick={handleSignUp} className="link m-l-5 choices__item--selectable">
            {t('signup')}
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center m-b-25">
          <span onClick={onRestorePassword} className="link choices__item--selectable">
            {t('restorePasswordProposal')}
          </span>
        </div>
        <LangDropdown />
      </AuthLayout>
    </>
  );
};
export default LoginEmail;
