import { EventParams, getAnalytics, logEvent } from 'firebase/analytics';

const analytics = getAnalytics();

interface IAddPaymentInfoEventParams {
  coupon?: EventParams['coupon'];
  currency?: EventParams['currency'];
  items?: EventParams['items'];
  payment_type?: EventParams['payment_type'];
  value?: EventParams['value'];
}

export const firebaseAddPaymentInfoEvent = (params: IAddPaymentInfoEventParams) => {
  logEvent(analytics, 'add_payment_info', params);
};

interface IPurchaseEventParams {
  value?: EventParams['value'];
  currency?: EventParams['currency'];
  transaction_id: EventParams['transaction_id'];
  tax?: EventParams['tax'];
  shipping?: EventParams['shipping'];
  items?: EventParams['items'];
  coupon?: EventParams['coupon'];
  affiliation?: EventParams['affiliation'];
}

export const firebasePurchaseEvent = (params: IPurchaseEventParams) => {
  logEvent(analytics, 'purchase', params);
};

interface ISignUpEventParams {
  method?: EventParams['method'];
}

export const firebaseSignUpEvent = (params: ISignUpEventParams) => {
  logEvent(analytics, 'sign_up', params);
};

interface ILogInEventParams {
  method?: EventParams['method'];
}

export const firebaseLogInEvent = (params: ILogInEventParams) => {
  logEvent(analytics, 'login', params);
};
