import { OrganizationDefaultValuesDTO } from '../../types';

export function mapOrgDefaultValues(defValues: OrganizationDefaultValuesDTO) {
  if (Object.keys(defValues).length === 0) {
    return null;
  }

  return {
    availableTimezones: defValues.available_timezones,
    commands: defValues.commands,
    weekDays: defValues.day_weeks,
    fromTime: defValues.from_time,
    optInText: defValues.opt_in_text,
    postWelcomeMessage: defValues.post_welcome_message,
    sendFromTime: defValues.send_from_time,
    sendToTime: defValues.send_to_time,
    timezone: defValues.timezone,
    toTime: defValues.to_time,
    utmCampaign: defValues.utm_campaign,
    utmMedium: defValues.utm_medium,
    utmSource: defValues.utm_source,
    welcomeMessage: defValues.welcome_message,
  };
}
