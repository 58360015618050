interface IProRateDTP {
  amount: number;
  day_difference: number;
  localized_amount: string;
}

interface IContactPackageDTO {
  amount: string;
  currency: string;
  id: string;
  items: number;
  localized_price: string;
  localized_price_per_item: string;
  price_per_item: string;
  prorate?: IProRateDTP;
}

export function mapContactPackages(items: IContactPackageDTO[]) {
  if (Array.isArray(items) && items.length === 0) {
    return [];
  }

  return items.map((p) => ({
    amount: p.amount,
    currency: p.currency,
    id: p.id,
    items: p.items,
    localizedPrice: p.localized_price,
    localizedPricePerItem: p.localized_price_per_item,
    pricePerItem: p.price_per_item,
    prorate: p.prorate
      ? {
          amount: p.prorate.amount,
          dayDifference: p.prorate.day_difference,
          localizedAmount: p.prorate.localized_amount,
        }
      : undefined,
  }));
}
