import { ChangeEvent, FC, InputHTMLAttributes, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  onUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  isLoading?: boolean;
}

export const FileUploader: FC<IProps> = (props) => {
  const { className, onUpload, id = 'upload-btn', isLoading, ...otherProps } = props;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onUpload?.(e);
  };

  return (
    <div className={className}>
      <div className="text-center">
        <input
          ref={inputRef}
          id={id}
          type="file"
          className="btn btn-primary"
          onChange={onChangeHandler}
          disabled={isLoading}
          hidden
          {...otherProps}
        />
        <label htmlFor={id} className={'btn btn-primary' + `${isLoading ? ' disabled' : ''}`}>
          {t('uploadFromYourComputer')}
          {isLoading ? (
            <>
              &nbsp; <span className="spinner-border spinner-border-sm" role="status" />
            </>
          ) : null}
        </label>
      </div>
    </div>
  );
};
