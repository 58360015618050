import axios from 'axios';

import { API_ENDPOINT } from '../config/environment';

export const baseApi = axios.create({
  baseURL: `${API_ENDPOINT}api/v1/`,
  timeout: 1000,
});

baseApi.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      request.headers['Authorization'] = `${authToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
