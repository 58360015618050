import { useEffect, useState } from 'react';

interface IUseCollapsiblePanelOptions {
  durationStart?: number;
  durationEnd?: number;
}
/**
 * Hook to handle collapsible panel
 * @param durationStart - delay before animation starts. Default value `1`ms
 * @param durationEnd - delay before animation ends. Default value `200`ms
 * @returns isOpen, handleOpen, handleClose, isAnimation
 */
export const useCollapsiblePanel = ({ durationStart = 1, durationEnd = 200 }: IUseCollapsiblePanelOptions = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [animationOnStart, setAnimationOnStart] = useState(false);
  const [animationOnEnd, setAnimationOnEnd] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnimationOnEnd(true);
    setTimeout(() => {
      setIsOpen(false);
      setAnimationOnStart(false);
    }, durationEnd);
  };

  useEffect(() => {
    if (isOpen) {
      setAnimationOnEnd(false);
      setTimeout(() => setAnimationOnStart(true), durationStart);
    }
  }, [isOpen]);

  const isAnimation = animationOnStart && !animationOnEnd;

  return { handleClose, handleOpen, isAnimation, isOpen };
};
