import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from '../Dropdown';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';

interface IProps {
  email: string;
}

export const UserDropdownMenu: FC<IProps> = ({ email }) => {
  const { t } = useTranslation();
  const { onLogOut } = useAuthContext();
  const navigate = useNavigate();

  const logoutHandler = () => {
    onLogOut();
    navigate('/');
  };

  const dropdownItems = useMemo(
    () => [
      {
        label: t('dashboard'),
        shortcut: 'P',
        iconStart: 'user',
        href: '/dashboard',
      },
      {
        label: t('logOut'),
        shortcut: 'L',
        iconStart: 'log-out',
        onClick: logoutHandler,
      },
    ],
    [t]
  );

  return (
    <Dropdown>
      <Dropdown.Trigger>
        <span>{email}</span>
        {/* <Avatar imageUrl={session?.avatarUrl} /> */}
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {dropdownItems.map(({ label, ...otherProps }, idx) => {
          return (
            <Dropdown.Item key={idx} {...otherProps}>
              {label}
            </Dropdown.Item>
          );
        })}
        {/* <Dropdown.Divider /> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};
