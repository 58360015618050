/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import { audienceAPIGetAudienceData, audienceAPIGetFilters } from '../../../api/audience';
import FilterBlock from './FilterBlock';
import CommonSelect from './CommonSelect';
import MobileMessagesTable from './MobileMessagesTable';
import MessagesTable from './MessagesTable';
import Pagination from './Pagination';
import Spinner from './Spinner';
import ErrorPopup from '../../../components/errors/ErrorPopup';

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);
  return debouncedValue;
}

type AudienceContentProps = {
  sendType?: boolean;
  detailsBlock?: boolean;
  onSendMessage?: (sendItems: any) => void;
};

const AudienceContent: React.FC<AudienceContentProps> = ({ sendType, onSendMessage, detailsBlock }) => {
  const { token } = useAuthContext();

  const [data, setData] = useState({
    page: 1,
    num_pages: 3,
    per_page: 20,
    count: 2,
    items: [
      {
        id: '',
        value: '',
        value_type: '',
        language: '',
        create_date: '',
        carrier: '',
        region: '',
        region_code: '',
      },
    ],
    companies: [
      {
        id: '',
        country: '',
        name: '',
        status: '',
      },
    ],
    types: ['phone', 'email', 'icloud'],
    regions: [
      {
        region: '',
      },
    ],
  });

  const [filterQuery, setFilterQuery] = useState(
    sendType
      ? { page: data.page, per_page: data.per_page, sort_by: 'desc', company: '' }
      : { page: data.page, per_page: data.per_page, sort_by: 'desc' }
  );
  const [loading, setLoading] = useState(false);

  const filterQueryes = useCallback(
    (query: any) => {
      return Object.assign(filterQuery, query);
    },
    [filterQuery]
  );

  const [error, setError] = useState<Error | null>(null);

  const filtersOnChange = useCallback(() => {
    (async () => {
      try {
        if (token) {
          const res = await audienceAPIGetFilters(token, filterQuery);
          if (res.code) {
            setIsPopup(true);
            setErrorText(`${res.message}. Error code: ${res.code}`);
          } else if ((!res.success && res.status && res.status !== 200) || !res) {
            setIsPopup(true);
            setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
          } else {
            setData(res);
            setLoading(true);
          }
        }
      } catch (e) {
        setError(e as Error);
        console.log(error);
      }
    })();
  }, [error, token, filterQuery]);

  useEffect(() => {
    setClearFilters(false);
  }, []);

  const [page, setPage] = useState(data.page);
  const nPages = data.num_pages;

  const [clearFilters, setClearFilters] = useState(false);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 1000);

  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [entriesValue, setEntriesValue] = useState('20');
  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [isMobile] = useState(window.innerWidth < 991);

  const deleteQueryes = useCallback(
    (query: any) => {
      for (const key in filterQuery) {
        if (key === query) {
          delete filterQuery[key as keyof typeof filterQuery];
        }
      }
    },
    [filterQuery]
  );

  const { t } = useTranslation();

  const [region, setRegion] = useState('');

  const onRegion = useCallback(
    (value: string | number) => {
      setRegion(value as string);
      if (value === 'select' || value === '') {
        deleteQueryes('region');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes({ region: value });
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, deleteQueryes]
  );

  const onCompanyId = useCallback(
    (value: string | number, name: string | undefined) => {
      setCompanyId(value as string);
      setCompanyName(name as string);
      if (name === 'select' || name === '') {
        deleteQueryes('company');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes({ company: value });
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, deleteQueryes]
  );

  const onEntries = useCallback(
    (value: string | number) => {
      filterQueryes({ per_page: value });
      filtersOnChange();
      setClearFilters(true);
      setEntriesValue(value as string);
      if (value === '20') {
        setClearFilters(false);
      }
    },
    [filterQueryes, filtersOnChange]
  );

  const [type, setType] = useState('');

  const onType = useCallback(
    (value: string | number) => {
      setType(value as string);
      if (value === 'select' || value === '') {
        deleteQueryes('type');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes({ type: value });
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, deleteQueryes]
  );

  const [sortBy, setSortBy] = useState('desc');

  const onSort = useCallback(
    (value: string | number) => {
      setSortBy(value as string);
      filterQueryes({ sort_by: value });
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const onSearch = useCallback(
    (value: string) => {
      const search = { search: value };
      if (value === '') {
        deleteQueryes('search');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes(search);
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, setClearFilters, deleteQueryes]
  );

  useEffect(() => {
    if (debouncedSearch !== '') {
      onSearch(debouncedSearch);
    } else {
      onSearch('');
    }
  }, [debouncedSearch, onSearch]);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const onCalendar = useCallback(
    (show: boolean) => {
      setShowDatePicker(!show);
      deleteQueryes('from_date');
      deleteQueryes('to_date');
      filtersOnChange();
    },
    [deleteQueryes, filtersOnChange]
  );

  const onClearFilters = useCallback(() => {
    setSearchText('');
    setClearFilters(false);
    setCompanyId(companyId);
    setCompanyName('');
    setFilterQuery({ page: 1, per_page: 20, sort_by: 'desc' });
    setPage(1);
    setEntriesValue('20');
    setSortBy('desc');
    setShowDatePicker(!showDatePicker);
    setType('');
    setRegion('');
    filtersOnChange();
  }, [companyId, filtersOnChange, showDatePicker]);

  const popupClose = useCallback((c: boolean) => {
    setIsPopup(c);
  }, []);

  const onChangeData = useCallback(
    (c: boolean) => {
      if (c) {
        (async () => {
          try {
            if (token) {
              const res = await audienceAPIGetAudienceData(token);
              if (res.code) {
                setIsPopup(true);
                setErrorText(`${res.message}. Error code: ${res.code}`);
              } else if ((!res.success && res.status && res.status !== 200) || !res) {
                setIsPopup(true);
                setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
              } else {
                setData(res);
              }
            }
          } catch (e) {
            console.log(e);
          }
        })();
      }
    },
    [token]
  );

  const onPage = useCallback(
    (page: number) => {
      setPage(page);
      filterQueryes({ page });
      filtersOnChange();
    },
    [filtersOnChange, filterQueryes]
  );

  const [csvData, setCsvData] = useState<any[]>([]);

  useEffect(() => {
    const arr: any[] = [];
    data.items.map((item: any) => {
      arr.push({ value: item.value, valueType: item.value_type, createDate: item.create_date });
    });
    setCsvData(arr);
  }, [data.items]);

  return (
    <div className="card-block adaptive-table">
      <div className="row m-0 justify-content-between align-items-center relative">
        {!loading ? <Spinner /> : null}
        <FilterBlock
          filterQuery={filterQuery}
          filtersOnChange={filtersOnChange}
          setClearFilters={setClearFilters}
          onEntries={onEntries}
          data={csvData}
          converterName="audience"
          entriesValue={entriesValue}
          onSort={onSort}
          sortBy={sortBy}
          onCalendar={onCalendar}
          showPicker={showDatePicker}
        />
      </div>
      <div className={`row m-0 ${detailsBlock ? 'justify-content-start' : 'justify-content-between'}`}>
        <div className={`col-auto col-xl-auto offset-0 mb-4 p-0 ${detailsBlock ? 'm-r-30' : 'm-r-0'}`}>
          <div className="row m-0 align-items-center no-gutters m-r-5">
            <div className="col-auto">
              <p className="mb-0 mr-2">{t('search')}</p>
            </div>
            <div className="col">
              <input
                name="search"
                onChange={(e) => setSearchText(e.target.value)}
                className="form-control"
                placeholder={t('searchInputTest')}
                value={searchText}
              />
            </div>
          </div>
        </div>
        {data.companies.length && !detailsBlock ? (
          <div className="col-12 p-0  col-sm-auto offset-0 mb-4">
            <div className="row m-0 align-items-center no-gutters">
              <div className="col-auto">
                <p className="mb-0 mr-2">{t('companyName')}</p>
              </div>
              <div className="col-auto offset-0">
                <CommonSelect
                  onChange={onCompanyId}
                  options={data.companies.map((i) => {
                    return { value: i.name, title: i.name };
                  })}
                  id={data.companies.map((i) => {
                    return i.id as string;
                  })}
                  name="companies"
                  startValue={companyName}
                />
              </div>
            </div>
          </div>
        ) : null}
        {data.types.length ? (
          <div className={`col-12 p-0  col-sm-auto offset-0 mb-4 ${detailsBlock ? 'm-r-30' : 'm-r-0'}`}>
            <div className="row m-0 align-items-center no-gutters">
              <div className="col-auto">
                <p className="mb-0 mr-2">{t('type')}</p>
              </div>
              <div className="col-auto offset-0">
                <CommonSelect
                  onChange={onType}
                  options={data.types.map((i) => {
                    return { value: i, title: i };
                  })}
                  name="type"
                  startValue={type}
                />
              </div>
            </div>
          </div>
        ) : null}
        {data.regions.length ? (
          <div className={`col-12 p-0  col-sm-auto offset-0 mb-4 ${detailsBlock ? 'm-r-30' : 'm-r-0'}`}>
            <div className="row m-0 align-items-center no-gutters">
              <div className="col-auto">
                <p className="mb-0 mr-2">{t('region')}</p>
              </div>
              <div className="col-auto offset-0">
                <CommonSelect
                  options={data.regions.map((i) => {
                    return { value: i.region, title: i.region };
                  })}
                  name="region"
                  onChange={onRegion}
                  startValue={region}
                />
              </div>
            </div>
          </div>
        ) : null}

        {clearFilters ? (
          <div className="d-flex mb-4">
            <div
              className="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center"
              onClick={onClearFilters}
            >
              <div className="d-flex justify-content-center align-items-center}">Clear filters?</div>
            </div>
          </div>
        ) : null}
      </div>
      {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}
      {data.items.length === 0 ? (
        <p className="text-center">{t('noResults')}</p>
      ) : (
        <>
          {loading ? (
            isMobile ? (
              <MobileMessagesTable data={data.items} onChange={onChangeData} />
            ) : (
              <MessagesTable
                data={data.items}
                onChange={detailsBlock ? undefined : onChangeData}
                sendType={sendType}
                onSendMessage={onSendMessage}
              />
            )
          ) : null}
          {loading ? <Pagination total={nPages} page={page} onChange={onPage} /> : null}
        </>
      )}
    </div>
  );
};
export default AudienceContent;
