import { FC } from 'react';
import { isImageUrl } from '../../../lib/isImageUrl';

interface IProps {
  url: string;
}
export const AttachmentPreview: FC<IProps> = (props) => {
  const { url } = props;

  return isImageUrl(url) ? (
    <div className="d-inline-flex">
      <img className="wid-100 object-fit-contain" src={url} />
    </div>
  ) : (
    <div className="d-inline-flex align-items-center justify-content-center flex-shrink-0 bg-secondary-subtle wid-100 hei-80">
      <span className="h3 mb-0 text-uppercase">{url.split('.').at(-1)}</span>
    </div>
  );
};
