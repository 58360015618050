import React from 'react';

type SpinnerProps = {
  top?: number;
};

const Spinner: React.FC<SpinnerProps> = ({ top }) => {
  return (
    <div
      style={{
        height: 170,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        position: 'absolute',
        backgroundColor: 'rgba(255,255,255,0.5)',
        zIndex: 9000,
        boxSizing: 'border-box',
      }}
    >
      <div className="spinner-border" style={{ marginTop: top }} />
    </div>
  );
};
export default Spinner;
