/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import AudienceContent from './common/AudienceContent';

const Audience: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div id="audience">
      <div className="main-body">
        <div className="page-wrapper">
          <div className="page-header-title">
            <h3 className="m-b-10">{t('audience')}</h3>
          </div>
          <div className="card">
            <AudienceContent />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Audience;
