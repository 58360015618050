/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import FeatherIcon from 'feather-icons-react';
import Spinner from './common/Spinner';
import ErrorPopup from '../../components/errors/ErrorPopup';
import { billingAPIDeletePaymentData, billingAPIGetPaymentData } from '../../api/billing';

const Billing: React.FC = () => {
  const { token } = useAuthContext();

  const [data, setData] = useState({
    editable: true,
    currency: '',
    pay_url: '',
    payment: {
      id: '',
      update_url: '',
      date_added: '',
      payment_method: '',
      card_type: '',
      last_four_digits: '',
      expiry_date: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [edit, setEdit] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [checkout, setCheckout] = useState(true);
  const [editable, setEditable] = useState(false);
  const [message, setMessage] = useState(false);

  const envVendor = parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID || '');
  const vendor = Number.isInteger(envVendor) ? envVendor : 0;

  useEffect(() => {
    if (data.payment) {
      setPaymentId(data.payment.id);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignoreREACT_APP_PADDLE_VENDOR_ID
    const Paddle = window.Paddle;
    Paddle.Setup({ vendor: vendor });
    Paddle.Environment.set(process.env.REACT_APP_PADDLE_ENVIRONMENT_SET);
  }, [data.pay_url, data.payment, vendor]);

  const editableDelay = useCallback(() => {
    setEditable(true);
    setCheckout(false);
    setEdit(false);
  }, []);

  const deleteDelay = useCallback(() => {
    setEditable(false);
    setCheckout(false);
    setEdit(false);
    setLoading(true);
  }, []);

  const delay = useCallback(() => {
    setTimeout(editableDelay, 4000);
    //onData()
  }, [editableDelay]);

  const onPaddle = useCallback((paymentUrl: string, successCallback?: () => void) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const Paddle = window.Paddle;

    Paddle.Checkout.open({
      method: 'inline',
      override: paymentUrl,
      allowQuantity: false,
      disableLogout: true,
      frameTarget: 'checkout-container',
      frameInitialHeight: 450,
      frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
      closeCallback: 'checkoutClosed',
      successCallback: successCallback,
    });
  }, []);

  const onData = useCallback(() => {
    (async () => {
      try {
        if (token) {
          const res = await billingAPIGetPaymentData(token);
          console.log(res);
          if (res.code) {
            setIsPopup(true);
            setErrorText(`${res.message}. Error code: ${res.code}`);
          } else if ((!res.success && res.status && res.status !== 200) || !res) {
            setIsPopup(true);
            setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
          } else {
            setData(res);
            if (!checkout) {
              setCheckout(true);
            }
            if (res.pay_url && res.pay_url !== '' && res.editable) {
              setEditable(false);
              onPaddle(res.pay_url, function () {
                console.log('i`m done!');
                delay();
                setLoading(false);
              });
            } else {
              if (res.payment.update_url && res.payment.update_url !== '') {
                setCheckout(false);
                setEditable(true);
              }
              setCheckout(false);
            }
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, checkout]);

  useEffect(() => {
    onData();
    if (data.editable === false) {
      setMessage(true);
    }
  }, [onData, data.editable]);

  const { t } = useTranslation();

  const onClose = useCallback(() => {
    setEdit(false);
  }, []);

  const onEdit = useCallback(() => {
    setEdit(true);
    if (data.payment) {
      console.log('Edit');
      onPaddle(data.payment.update_url, function () {
        console.log('i`m edit!');
        setEdit(false);
        onData();
      });
    }
  }, [onPaddle, data.payment, onData]);

  const onDeletePaymentMethod = useCallback(() => {
    (async () => {
      try {
        if (token) {
          setLoading(false);
          const res = await billingAPIDeletePaymentData(token, paymentId);
          if (res.code) {
            setIsPopup(true);
            setErrorText(`${res.message}. Error code: ${res.code}`);
          } else if ((!res.success && res.status && res.status !== 200) || !res) {
            setIsPopup(true);
            setErrorText(res.status ? `Something went wrong. Error code: ${res.status}` : 'Something went wrong.');
          } else {
            console.log('Deleted successfuly');
            onData();
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(deleteDelay, 5000);
        //setTimeout(onLoad, 5000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId, token, deleteDelay]);

  const popupClose = useCallback((c: boolean) => {
    setIsPopup(c);
  }, []);

  return (
    <div id="campaign-details">
      <div className="main-body">
        <div className="page-wrapper">
          <div className="card form-card checkout">
            {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}
            {!loading ? <Spinner top={30} /> : null}
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>{t('paymentMethodTitle')}</h5>
              {editable && !checkout && loading && !message ? (
                <button className="btn btn-sm btn-light" onClick={edit ? onClose : onEdit}>
                  <FeatherIcon className="feather m-r-5 m-t-3m" icon="edit" size={16} />
                  {edit ? t('Close') : t('paymentMethodTab.editButton')}
                </button>
              ) : null}
            </div>
            <div className="sender_name collapse show">
              <div className="card-block task-details">
                <div className="mt-n4">
                  <div className="alert alert-info mt-2 mb-2">
                    {message ? t('noManagePaymentMethod') : t('warnings.no-store-card')}
                  </div>
                </div>
                <div style={{ display: (edit && !message) || (checkout && !message) ? 'block' : 'none' }}>
                  <div className="checkout-container d-flex justify-content-center"></div>
                </div>

                {data.payment && !edit && !checkout && loading && !message ? (
                  <div className="row mb-0">
                    <div className="col-sm-12">
                      <div className="table-responsive">
                        <table className="table invoice-detail-table">
                          <tbody>
                            <tr>
                              <td>
                                <h6>{t('paymentMethodTab.paymentType')}:</h6>
                                <p className="m-0">{data.payment.payment_method}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6>{t('paymentMethodTab.cardType')}:</h6>
                                <p className="m-0">{data.payment.card_type}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6>{t('paymentMethodTab.cardNumber')}:</h6>
                                <p className="m-0">{`xxxx-xxxx-xxxx-${data.payment.last_four_digits}`}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h6>{t('paymentMethodTab.expiry')}:</h6>
                                <p className="m-0">{data.payment.expiry_date}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : null}
                {editable && !checkout && loading && !message ? (
                  <div className="row juatify-content-start">
                    <div className="col col-12">
                      <div className="d-flex flex-row justify-content-end">
                        <button className="btn btn-outline-danger shadow-2" onClick={onDeletePaymentMethod}>
                          {t('delete')}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/*   <div className="card form-card checkout">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>Billing info</h5>
            </div>
            <div className="sender_name collapse show">
              <div className="card-block task-details">
                <form>
                  <div className="row justify-content-start">
                    <div className="col-12 col-sm-6 mb-2 form-group">
                      <label htmlFor="company" className="">
                        Company
                      </label>
                      <input id="company" type="text" className="form-control" name="company" />
                      <div
                        data-lastpass-icon-root=""
                        style={{
                          position: 'relative',
                          height: '0px !important',
                          width: '0px !important',
                          float: 'left',
                        }}
                      ></div>
                    </div>
                    <div className="col-12 col-sm-6 mb-2 form-group">
                      <label htmlFor="name" className="">
                        Name
                      </label>
                      <input id="name" type="text" className="form-control" name="name" />
                    </div>
                    <div className="col-12 mb-2 form-group">
                      <label htmlFor="address" className="">
                        Address
                      </label>
                      <input id="address" type="text" className="form-control" name="address" />
                    </div>
                    <div className="col-12 col-sm-6 mb-2 form-group">
                      <label htmlFor="city" className="">
                        City
                      </label>
                      <input id="city" type="text" className="form-control" name="city" />
                    </div>
                    <div className="col-12 col-sm-6 mb-2 form-group">
                      <label htmlFor="state" className="">
                        State/Province/Region
                      </label>
                      <input id="state" type="text" className="form-control" name="state" />
                    </div>
                    <div className="col-12 col-sm-6 mb-2 form-group">
                      <label htmlFor="postalCode" className="">
                        Zip/Postcode
                      </label>
                      <input id="postalCode" type="text" className="form-control" name="postalCode" />
                      <small className="form-text text-muted">Used to calculate VAT or sales tax in invoices</small>
                    </div>
                    <div className="col-12 col-sm-6 mb-2 form-group">
                      <label htmlFor="country" className="">
                        Country
                      </label>
                    </div>
                    <div className="col-12 mb-2 form-group">
                      <label htmlFor="tax_number" className="">
                        Tax number
                      </label>
                      <input id="tax_number" type="text" className="form-control" name="tax_number" />
                      <small className="form-text text-muted">
                        VAT, GST, or Sales taxes is not included in our prices. If you are a business, make sure you
                        enter your TAX number to prove your business status and thus remove VAT or GST from the price.
                        Sales tax may apply depending on your business location or zip code.
                      </small>
                    </div>
                    <div className="col-12 mb-2 form-group">
                      <label htmlFor="phoneNumber" className="">
                        Phone number
                      </label>
                      <input id="phoneNumber" type="text" className="form-control" name="phoneNumber" />
                    </div>
                    <div className="col-12 mb-2 form-group">
                      <label htmlFor="email" className="">
                        Accountant email
                      </label>
                      <input id="email" type="text" className="form-control" name="email" />
                      <small className="form-text text-muted">Additional email for invoicing</small>
                    </div>
                  </div>
                  <div className="row justify-content-start">
                    <div className="col col-auto">
                      <button disabled={false} className="btn btn-primary shadow-2 mt-4">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Billing;
