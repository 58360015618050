import i18n from '../i18n';

export type Language = 'en' | 'ru' | 'ua';

let currentLanguage = (localStorage.getItem('language') || 'en') as Language;

export const changeLanguage = (lang: Language) => {
  i18n.changeLanguage(lang);
  currentLanguage = lang;
  localStorage.setItem('language', lang);
};

export const getCurrentLanguage = (): Language => {
  return currentLanguage;
};
