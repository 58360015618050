import { FC, useMemo, useState } from 'react';
import { range } from '../../../lib/range';
import { useUncontrolled } from '../../../hooks';

const DOTS = 'dots';

interface IProps {
  total: number;
  page?: number;
  initialPage?: number;
  siblings?: number;
  boundaries?: number;
  onChange?: (page: number) => void;
  // setPage: (page: number) => void;
}

const Pagination: FC<IProps> = (props) => {
  const { total, page: controlledPage, initialPage = 1, siblings = 1, boundaries = 1, onChange } = props;

  const [activePage, setActivePage] = useUncontrolled({
    value: controlledPage,
    defaultValue: initialPage,
    finalValue: 1,
    onChange,
  });

  const paginationRange = useMemo((): (number | 'dots')[] => {
    const totalPageNumbers = siblings * 2 + 3 + boundaries * 2;
    if (totalPageNumbers >= total) {
      return range(1, total);
    }

    const leftSiblingIndex = Math.max(activePage - siblings, boundaries);
    const rightSiblingIndex = Math.min(activePage + siblings, total - boundaries);

    const shouldShowLeftDots = leftSiblingIndex > boundaries + 2;
    const shouldShowRightDots = rightSiblingIndex < total - (boundaries + 1);

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = siblings * 2 + boundaries + 2;
      return [...range(1, leftItemCount), DOTS, ...range(total - (boundaries - 1), total)];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = boundaries + 1 + 2 * siblings;
      return [...range(1, boundaries), DOTS, ...range(total - rightItemCount, total)];
    }

    return [
      ...range(1, boundaries),
      DOTS,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      ...range(total - boundaries + 1, total),
    ];
  }, [total, siblings, controlledPage ?? activePage]);

  const items = paginationRange.map((_page, idx) => {
    if (_page === 'dots') {
      return (
        <li key={idx} className="page-item">
          <span className="page-link">...</span>
        </li>
      );
    }

    return (
      <li key={idx} className={'page-item' + `${activePage == _page ? ' active' : ''}`}>
        <button className="page-link" onClick={() => setPage(_page)} disabled={activePage == _page}>
          {_page}
        </button>
      </li>
    );
  });

  const setPage = (pageNumber: number) => {
    // onChange?.(pageNumber);
    if (pageNumber <= 0) {
      setActivePage(1);
    } else if (pageNumber > total) {
      setActivePage(total);
    } else {
      setActivePage(pageNumber);
    }
  };

  const goToNextPage = () => {
    if (activePage !== total) setPage(activePage + 1);
  };

  const goToPrevPage = () => {
    if (activePage !== 1) setPage(activePage - 1);
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className={'page-item' + `${activePage === 1 ? ' disabled' : ''}`}>
          <button className="page-link" aria-label="Previous" onClick={goToPrevPage}>
            <span aria-hidden="true">«</span>
            <span className="sr-only">Previous</span>
          </button>
        </li>
        {items}
        <li className={'page-item' + `${activePage === total ? ' disabled' : ''}`}>
          <button className="page-link" aria-label="Next" onClick={goToNextPage}>
            <span aria-hidden="true">»</span>
            <span className="sr-only">Next</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
