import { API_ENDPOINT } from '../config/environment';
import { TRoles } from '../types';
import { errorsHandler } from './utils';

export const getTeamAPI = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/team/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const getAllOrganizationsAPI = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/team/organizations/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const inviteParticipantAPI = async (token: string, data: { email: string }) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/team/participants/new/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const deleteInviteAPI = async (token: string, userId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/team/invited/${userId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const getOrganizationParticipantsAPI = async (token: string, organizationId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/team/participants/${organizationId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const updateParticipantAPI = async (
  token: string,
  organizationId: string,
  data: { id: string; role: TRoles }
) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/team/participants/${organizationId}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const deleteParticipantAPI = async (token: string, userId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/team/participants/${userId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const updateParticipantOrganizationsAPI = async (
  token: string,
  data: { organization_ids: string[]; user_id: string }
) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/team/participants/organizations/', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};

export const deleteParticipantFromOrganizationAPI = async (
  token: string,
  data: { organization_id: string; user_id: string }
) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/team/participants/organizations/', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};
