import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TDaysOfTheWeek } from '../../../types';

interface IProps {
  days: TDaysOfTheWeek[] | null;
  className?: string;
  onChange?: (days: TDaysOfTheWeek[]) => void;
}
export const WeekDaysCheckboxes: FC<IProps> = (props) => {
  const { days, className, onChange } = props;
  const { t } = useTranslation();

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const newDays = checked ? [...days!, value as TDaysOfTheWeek] : days!.filter((day) => day !== value);

    onChange?.(newDays);
  };

  if (days === null) return null;

  return (
    <div className={className}>
      <label className="form-label">{t('daysWeekOfSending')}:</label>
      <div className="">
        <div className="form-check form-check-inline">
          <input
            id="monday-cb"
            className="form-check-input input-primary"
            type="checkbox"
            name="day_weeks"
            onChange={changeHandler}
            defaultChecked={days.includes('monday')}
            value="monday"
          />
          <label className="form-check-label" htmlFor="monday-cb">
            {t('weekdays.monday')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            id="tuesday-cb"
            className="form-check-input input-primary"
            type="checkbox"
            name="day_weeks"
            onChange={changeHandler}
            defaultChecked={days.includes('tuesday')}
            value="tuesday"
          />
          <label className="form-check-label" htmlFor="tuesday-cb">
            {t('weekdays.tuesday')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            id="wednesday-cb"
            className="form-check-input input-primary"
            type="checkbox"
            name="day_weeks"
            onChange={changeHandler}
            defaultChecked={days.includes('wednesday')}
            value="wednesday"
          />
          <label className="form-check-label" htmlFor="wednesday-cb">
            {t('weekdays.wednesday')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            id="thursday-cb"
            className="form-check-input input-primary"
            type="checkbox"
            name="day_weeks"
            onChange={changeHandler}
            defaultChecked={days.includes('thursday')}
            value="thursday"
          />
          <label className="form-check-label" htmlFor="thursday-cb">
            {t('weekdays.thursday')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            id="friday-cb"
            className="form-check-input input-primary"
            type="checkbox"
            name="day_weeks"
            onChange={changeHandler}
            defaultChecked={days.includes('friday')}
            value="friday"
          />
          <label className="form-check-label" htmlFor="friday-cb">
            {t('weekdays.friday')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            id="saturday-cb"
            className="form-check-input input-primary"
            type="checkbox"
            name="day_weeks"
            onChange={changeHandler}
            defaultChecked={days.includes('saturday')}
            value="saturday"
          />
          <label className="form-check-label" htmlFor="saturday-cb">
            {t('weekdays.saturday')}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            id="sunday-cb"
            className="form-check-input input-primary"
            type="checkbox"
            name="day_weeks"
            onChange={changeHandler}
            defaultChecked={days.includes('sunday')}
            value="sunday"
          />
          <label className="form-check-label" htmlFor="sunday-cb">
            {t('weekdays.sunday')}
          </label>
        </div>
      </div>
      <small className="form-text text-muted">{t('daysOfWeekHintText')}</small>
    </div>
  );
};
