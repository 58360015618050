import type { InferOutput } from 'valibot';

import { pipe, object, transform, string, nonEmpty } from 'valibot';

export const CommandsFormSchema = object({
  command: object({
    value: pipe(string(), nonEmpty('Please enter the command.')),
    text: pipe(string(), nonEmpty('Please enter the text.')),
    subject: pipe(string(), nonEmpty('Please enter the subject.')),
    action: pipe(
      string(),
      transform((input) => parseInt(input))
    ),
  }),
});

export type ICommandsForm = InferOutput<typeof CommandsFormSchema>;
