import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Language, changeLanguage } from '../../../utils/localization';

const items = ['languages_real.en', 'languages_real.ua'];

export const LangDropdown: FC = () => {
  const { t } = useTranslation();

  const [active, setActive] = useState(false);

  const toggle = () => setActive((prevState) => !prevState);

  const [lang, setLang] = useState('languages_real.en');

  const onLanguageChange = useCallback((lang: Language) => {
    changeLanguage(lang);
  }, []);

  const l = lang.substr(-2);

  useEffect(() => {
    onLanguageChange(l as Language);
  }, [onLanguageChange, l]);

  const handleItemClick = useCallback(
    (item: string) => {
      setLang(item);
      toggle();
      onLanguageChange(l as Language);
    },
    [onLanguageChange, l]
  );

  return (
    <div className="row justify-content-center">
      <div className="dropdown justify-content-center d-flex">
        <div className="btn btn-sm btn-outline-primary dropdown-toggle d-flex justify-content-center align-items-center form-floating">
          <div className="d-flex justify-content-center align-items-center}" onClick={toggle}>
            <span
              className={`language-flag ${lang === 'languages_real.ua' ? 'language-flag_ua' : 'language-flag_en'} m-r-5 m-t-4`}
            />
            {t(lang)}
          </div>
          <div className="select-items">
            {active &&
              items.map((item, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => handleItemClick(item)}
                    className={`${item === lang ? 'd-n' : 'dropdown-menu language_menu show'}`}
                  >
                    {active && (
                      <div className="d-flex align-items-center}">
                        <span
                          className={`language-flag ${lang === 'languages_real.ua' ? 'language-flag_en' : 'language-flag_ua'} m-r-5 m-t-6`}
                        />
                        {t(item)}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
