import { ICampaignDTO } from '../../types';

export function mapCampaign(campaign: ICampaignDTO) {
  if (!campaign) {
    return null;
  }

  return {
    attachments: campaign.attachments,
    channel: campaign.channel,
    channels: campaign.channels,
    countries: campaign.countries,
    createDate: campaign.create_date,
    weekDays: campaign.day_weeks,
    effect: campaign.effect,
    effects: campaign.effects,
    fromDate: campaign.from_date,
    fromTime: campaign.from_time,
    id: campaign.id,
    isEditable: campaign.is_editable,
    list: campaign.list,
    lists: campaign.lists,
    name: campaign.name,
    organizationId: campaign.organization_id,
    status: campaign.status,
    subject: campaign.subject,
    sendFromTime: campaign.send_from_time,
    sendToTime: campaign.send_to_time,
    text: campaign.text,
    timezone: campaign.timezone,
    timezones: campaign.timezones,
    toTime: campaign.to_time,
    utmCampaign: campaign.utm_campaign,
    utmMedium: campaign.utm_medium,
    utmSource: campaign.utm_source,
  };
}
