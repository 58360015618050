import { API_ENDPOINT } from '../config/environment';
import { errorsHandler } from './utils';

interface IBuyPackageParams {
  packageId: string;
  organizationId: string;
  paymentMethodId: string;
}

/* PADDLE start */
export const billingAPIGetPaymentData = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/billing/payment-method/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
};

export const billingAPIDeletePaymentData = async (token: string, id: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/billing/payment-method/${id}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify(id),
  });
  return errorsHandler(response);
};

/* PADDLE end */

/* STRIPE start */
export const getPaymentMethods = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/billing/payment-method-stripe/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
      'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return errorsHandler(response);
};

export const updatePaymentMethod = async (token: string, paymentMethodId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/billing/payment-method-stripe/${paymentMethodId}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
      'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return errorsHandler(response);
};

export const deletePaymentMethod = async (token: string, paymentMethodId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/billing/payment-method-stripe/${paymentMethodId}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
      'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return errorsHandler(response);
};

export const createPaymentIntent = async (token: string, customerId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/billing/payment-method-stripe-intent/${customerId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
      'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return errorsHandler(response);
};

/* STRIPE end */

export const getOrganizationPackages = async (token: string, organizationId: string) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/packages/${organizationId}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
      'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return errorsHandler(response);
};

export const buyPackage = async (token: string, { organizationId, packageId, paymentMethodId }: IBuyPackageParams) => {
  const response = await fetch(
    API_ENDPOINT + `api/v1/dashboard/buy-package/${organizationId}/${packageId}/${paymentMethodId}/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
        'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    }
  );
  return errorsHandler(response);
};
