/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { authAPIGetSignInData, authAPIGetSignUpData, authAPIPostSignUpData, authAPIPutSignUpData } from '../../api';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import { metaPixelSuccessRegistrationEvent } from '../../lib/analytics/metaPixelEvents';
import { firebaseSignUpEvent } from '../../lib/analytics/firebaseEvents';
import ErrorPopup from '../../components/errors/ErrorPopup';
import RegistrationAlert from '../../components/errors/RegistrationAlert';
import AuthLayout from '../../components/layout/AuthLayout';
import CountrySelect from '../../components/elements/CountrySelect';
import PhoneCodeSelect from '../../components/elements/PhoneCodeSelect';
import { LangDropdown } from '../../components/common';

const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneNumberRegion, setPhoneNumberRegion] = useState('');
  const [hideEmail, setHideEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [isEmailEditable, setIsEmailEditable] = useState<boolean>();
  const [affiliateId, setAffiliateId] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [checked, setChecked] = useState(false);
  const [country, setCountry] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [signUpReady, setSignUpReady] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [countries, setCountries] = useState([]);

  const { onSignUpIdUpdated, signUpId } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!signUpReady) {
          if (!signUpId && !searchParams.get('invite')) {
            const res = await authAPIGetSignUpData();

            if (res.id) {
              onSignUpIdUpdated(res.id);
              res.countries.map((i: any) => {
                if (i.code === res.phone_number_region) {
                  setPhoneCode(i.phone_code);
                }
              });
              setFirstName(res.first_name);
              setLastName(res.last_name);
              setPhone(res.phone_number);
              setEmail(res.email);
              setCountry(res.country);
              setPhoneNumberRegion(res.phone_number_region);
              setValidEmail(true);
              setAffiliateId(res.affiliate_id);
              setCountries(res.countries);
            }
          } else {
            const res = await authAPIGetSignInData(searchParams.get('invite')! ?? signUpId);
            if (res) {
              res.countries.map((i: any) => {
                if (i.code === res.phone_number_region) {
                  setPhoneCode(i.phone_code);
                }
              });
              setFirstName(res.first_name);
              setLastName(res.last_name);
              setPhone(res.phone_number);
              setEmail(res.email);
              setIsEmailEditable(res.email_enabled);
              setCountry(res.country);
              setPhoneNumberRegion(res.phone_number_region);
              setValidEmail(true);
              setAffiliateId(res.affiliate_id);
              setCountries(res.countries);
            }
          }
        }
      } catch (e) {
        console.log(e);
        setError(e as Error);
      } finally {
        setSignUpReady(true);
      }
    })();
  }, [onSignUpIdUpdated, signUpId, signUpReady]);

  const timeZoneFormatted = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onUpdateFields = useCallback(async () => {
    if (!validEmail && email != '') {
      setHideEmail(false);
    }
    if (signUpId && validEmail) {
      const obj = {
        first_name: firstName,
        last_name: lastName,
        email,
        country,
        phone_number: phone,
        timezone: timeZoneFormatted,
        id: signUpId,
        affiliate_id: affiliateId,
        phone_number_region: phoneNumberRegion,
      };

      for (const key in obj) {
        if (!obj[key as keyof typeof obj]) {
          delete obj[key as keyof typeof obj];
        }
      }
      try {
        const res = await authAPIPutSignUpData(signUpId, obj);

        if (res.code === 555 || res.code === 180) {
          setErrorText(t('forms.submitMessage.errors.180'));
          setIsPopup(true);
        }
      } catch (e) {
        setError(e as Error);
        console.log(e);
      }
    }
  }, [
    firstName,
    lastName,
    email,
    country,
    phone,
    signUpId,
    timeZoneFormatted,
    validEmail,
    t,
    affiliateId,
    phoneNumberRegion,
  ]);

  const handleEmailAddress = useCallback((event: any) => {
    const value = event.target.value;
    const EMAIL_REGEXP = /^\S+@\S+\.\S+$/;
    if (EMAIL_REGEXP.test(value)) {
      setValidEmail(true);
      setEmail(value.trim());
      setEmailMessage(false);
    } else if (value === '') {
      setHideEmail(true);
      setEmail(value.trim());
    } else {
      setEmail(value.trim());
      setValidEmail(false);
      setEmailMessage(false);
    }
  }, []);

  const handlePhone = useCallback((event: any) => {
    const value = event.target.value;
    setPhone(value.trim());
  }, []);

  const handleFirstName = useCallback((event: any) => {
    const value = event.target.value;
    setFirstName(value.trim());
  }, []);

  const handleLastName = useCallback((event: any) => {
    const value = event.target.value;
    setLastName(value.trim());
  }, []);

  const handleChecked = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const handleRegionCode = useCallback(
    (phoneCountry: string) => {
      countries.map((country: any) => {
        setPhoneNumberRegion(phoneCountry);
        if (country.code === phoneCountry) {
          setPhoneCode(country.phone_code);
        }
      });
    },
    [countries]
  );

  const approved = useMemo(() => {
    if (email && firstName != undefined && phone && country != '' && checked && lastName != undefined) {
      return true;
    }
    return false;
  }, [email, firstName, phone, country, checked, lastName]);

  const onInput = async (e: FormEvent) => {
    e.preventDefault();
    if (!loading && signUpId && approved) {
      try {
        setLoading(true);

        const onSubmitRequest = {
          id: signUpId,
          email: email,
          first_name: firstName,
          last_name: lastName,
          country: country,
          phone_number: phone,
          affiliate_id: affiliateId,
          timezone: timeZoneFormatted,
          phone_number_region: phoneNumberRegion,
        };
        const res = await authAPIPostSignUpData(onSubmitRequest);
        if (res.success) {
          const userData = {
            email,
            firstName,
            lastName,
            country,
            phone: phoneCode + phone,
          };
          localStorage.setItem('user', JSON.stringify(userData));

          metaPixelSuccessRegistrationEvent();
          firebaseSignUpEvent({ method: 'dashboard' });
          if (res.authorization_type === 1) {
            navigate('/login-code-sign-up', {
              state: {
                email,
              },
            });
          } else if (res.authorization_type === 2) {
            navigate('/login-password', { state: { email } });
          }
        }
        if (res.code) {
          setIsPopup(true);
          setErrorText(t(`forms.submitMessage.errors.${res.code}`));
          if (res.code === 1020) {
            setEmailMessage(true);
            setErrorText(t(`forms.invite.errors.${res.code}`));
          }
          if (res.code === 555) {
            setEmailMessage(true);
            setErrorText(t('forms.submitMessage.errors.180'));
          }
        } else {
          //ERROR
          setErrorText(t(`forms.submitMessage.errors.100`));
          navigate('/');
        }
      } catch (e) {
        setError(e as Error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  console.log(phone);

  const popupClose = useCallback((c: boolean) => {
    setIsPopup(c);
  }, []);

  return (
    <AuthLayout signUp>
      {isPopup ? <ErrorPopup popupClose={popupClose} isPopup={isPopup} errorCode={errorText} /> : null}
      <h4 className="text-center f-w-500 mt-4 mb-3">{t('signup')}</h4>
      <form onSubmit={onInput}>
        <div className="form-group mb-3 t-a-l">
          <label htmlFor="email" className="m-b-5">
            {t('vcard.forms.email')}
          </label>
          <input
            id="email"
            type="email"
            className={`form-control ${!validEmail && !hideEmail && 'invalid'} `}
            onChange={handleEmailAddress}
            onBlur={onUpdateFields}
            required
            value={email || ''}
            readOnly={!isEmailEditable}
          />
          {!validEmail && !hideEmail ? (
            <small className="form-text error-message"> {t('errors.general.550')}</small>
          ) : null}
          {emailMessage ? <RegistrationAlert text={t('forms.invite.errors.1020')} /> : null}
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group mb-3 t-a-l">
              <label htmlFor="firstName" className="m-b-5">
                {t('invitePage.table.firstName')}
              </label>
              <input
                onChange={handleFirstName}
                onBlur={onUpdateFields}
                type="text"
                id="firstName"
                className="form-control"
                required
                value={firstName || ''}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-3 t-a-l">
              <label htmlFor="lastName" className="m-b-5">
                {t('invitePage.table.lastName')}
              </label>
              <input
                onChange={handleLastName}
                onBlur={onUpdateFields}
                type="text"
                id="lastName"
                required
                className="form-control"
                value={lastName || ''}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3 align-items-center">
          <div className="col-sm-4">
            <div className="form-group code-select t-a-l">
              <label htmlFor="tel" className="m-b-5">
                {t('phoneNumber')}
              </label>
              <PhoneCodeSelect onPhoneRegion={handleRegionCode} onBlur={onUpdateFields} data={countries} />
            </div>
          </div>
          <div className="col-sm-8">
            <div className="t-a-l">
              <div className="w-100 d-flex align-items-center col-sm-8 form-control-region">
                <span style={{ marginRight: 5 }}>(+{phoneCode + ''})</span>
                <input
                  minLength={9}
                  maxLength={15}
                  onChange={handlePhone}
                  onBlur={onUpdateFields}
                  type="tel"
                  id="phone"
                  className="region-input"
                  required
                  value={phone ? phone : ''}
                />
              </div>
            </div>
          </div>
          <small style={{ paddingLeft: 15 }} className="form-text text-muted">
            {t('forms.checkout.phone_description')}
          </small>
        </div>
        <CountrySelect onCountry={setCountry} onBlur={onUpdateFields} data={countries} />
        <div className="d-flex mt-1 justify-content-between">
          <div className="form-check">
            <input
              onChange={handleChecked}
              className="form-check-input input-primary"
              type="checkbox"
              id="customCheckc1"
              required
            />
            <label className="form-check-label text-muted" htmlFor="customCheckc1">
              {t('termsRulesAgreement')}
              <a href="https://loopmessage.com/server-terms" rel="noopener noreferrer" target="blank">
                {t('termsOfUse')}
              </a>
              {t('and')}
              <a href="https://loopmessage.com/server-privacy" rel="noopener noreferrer" target="blank">
                {t('privacyPolicy')}
              </a>
            </label>
          </div>
        </div>
        <div className="text-center m-t-25 m-b-15">
          <button type="submit" className="btn btn-primary shadow px-sm-4" disabled={loading}>
            {t('continue')}
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center m-b-25">
          <p className="f-w-500 text-muted f-13 m-b-0">{t('signinProposal')}</p>
          <span
            role="button"
            className="link m-l-5"
            onClick={() => {
              navigate('/login-email');
            }}
          >
            {t('signin')}
          </span>
        </div>
      </form>
      <LangDropdown />
    </AuthLayout>
  );
};
export default SignUp;
