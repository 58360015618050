import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ref as firebaseRef } from 'firebase/storage';

import { IOrganizationDefaultValues } from '../../../../types';
import { storage } from '../../../../config/firebase';
import { isImage } from '../../../../lib/isImage';
import { getImageDimensions } from '../../../../lib/getImageDimensions';
import { uploadToFirebaseStorage } from '../../../../lib/firebase/uploadToFirebaseStorage';
import { deleteFromFirebaseStorage } from '../../../../lib/firebase/deleteFromFirebaseStorage';
import { createThumbObjectFromUrl } from '../../../../lib/createThumbObjectFromUrl';
import { FileUploader, FormElementError, UrlUploader, UploadedFilePreview } from '../../../common';

interface IProps {
  organizationId?: string;
  className?: string;
  formErrors?: any;
  initialValue?: IOrganizationDefaultValues['postWelcomeMessage'];
}
export const PostWelcomeUploader: FC<IProps> = (props) => {
  const { organizationId, formErrors: errors, initialValue } = props;
  const { t } = useTranslation();

  const postWelcomeThumbnailsRef = useRef<HTMLDivElement>(null);

  const [formErrors, setFormErrors] = useState<any>(errors);
  const [postWelcomeThumbnails, setPostWelcomeThumbnails] = useState<any[]>(
    initialValue?.attachments.length
      ? initialValue.attachments.map((attachment) => createThumbObjectFromUrl(attachment))
      : []
  );
  const [postWelcomeUploadedFiles, setPostWelcomeUploadedFiles] = useState<string[]>(initialValue?.attachments ?? []);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

  const addUrlHandler = async (thumb: any) => {
    setPostWelcomeThumbnails((prev) => [...prev, thumb]);
    setPostWelcomeUploadedFiles((prev) => [...prev, thumb.originalUrl]);
  };

  const uploadFileHandler = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement)?.files;

    if (!files?.length) {
      return;
    }

    setFormErrors(undefined);

    setIsLoadingFile(true);

    await Array.fromAsync(files, async (f) => {
      await uploadFile(f);
      await createThumbnail(f);
      return f;
    });

    setIsLoadingFile(false);

    async function uploadFile(file: File) {
      const validate = validateFile(file);
      if (!validate.success) {
        setFormErrors({
          'post_welcome_message.attachments': [validate.message],
        });
        return;
      }

      const uploadedUrl = await uploadToFirebase(file);

      setPostWelcomeUploadedFiles((prev) => [...prev, uploadedUrl]);
    }

    async function createThumbnail(file: File) {
      return new Promise((resolve, reject) => {
        const { name, size, type: fileType } = file;
        const reader = new FileReader();
        const validate = validateFile(file);
        if (!validate.success) {
          setFormErrors({
            'post_welcome_message.attachments': [validate.message],
          });
          reject();
          return;
        }

        reader.onload = async (event) => {
          const result = event.target?.result as string;
          let width = null;
          let height = null;
          if (isImage(name)) {
            const dimensions = await getImageDimensions(result);
            width = dimensions.width;
            height = dimensions.height;
          }
          const thumbObj = {
            type: isImage(name) ? 'image' : 'file',
            name,
            size: Math.round(size / 1024),
            width,
            height,
            dataUrl: result,
          };

          setPostWelcomeThumbnails((prev) => [...prev, thumbObj]);

          resolve(result);
        };

        reader.readAsDataURL(file);
      });
    }
  };

  function validateFile(file: File): { success: boolean; message?: string } {
    const error = {
      success: false,
      message: '',
    };

    if (file.size / 1024 > +process.env.REACT_APP_FILE_SIZE_LIMIT!) {
      error.message = 'The file size limit is reached';
      return error;
    }

    return { success: true };
  }

  const uploadToFirebase = async (file: File): Promise<string> => {
    const fileName = organizationId
      ? `${organizationId}/default_values/${Date.now()}-${file.name}`
      : `non-organization/${Date.now()}-${file.name}`;
    const storageRef = firebaseRef(storage, fileName);
    return await uploadToFirebaseStorage(storageRef, file);
  };

  const deleteFileHandler = async (index: number): Promise<any> => {
    deleteFile(index);
    removeThumbnail(index);
  };

  const deleteFile = (index: number) => {
    if (!postWelcomeThumbnails[index]?.originalUrl) {
      deleteFromFirebaseStorage(firebaseRef(storage, postWelcomeUploadedFiles[index]));
    }

    setPostWelcomeUploadedFiles(postWelcomeUploadedFiles.toSpliced(index, 1));
  };

  const removeThumbnail = (index: number) => {
    const blobUrl = postWelcomeThumbnails[index].blobUrl;
    blobUrl && URL.revokeObjectURL(blobUrl);

    setPostWelcomeThumbnails(postWelcomeThumbnails.toSpliced(index, 1));
  };

  return (
    <div>
      <input
        className={formErrors?.['post_welcome_message.attachments']?.length ? ' is-invalid' : ''}
        type="hidden"
        name="post_welcome_message.attachments"
        value={JSON.stringify(postWelcomeUploadedFiles)}
      />
      <label className="form-label">{t('attachments')}:</label>
      <UrlUploader onSuccess={(thumb) => addUrlHandler(thumb)} />
      <small className="form-text text-muted">
        {t('vcard.forms.photoUrlHelp', { size: +process.env.REACT_APP_FILE_SIZE_LIMIT! / 1024 })}
      </small>

      <span className="d-block text-center my-3">{t('or').toUpperCase()}</span>

      <FileUploader
        id="post-welcome-upload-btn"
        onUpload={(e) => uploadFileHandler(e)}
        isLoading={isLoadingFile}
        multiple
      />

      {formErrors?.['post_welcome_message.attachments']?.length ? (
        <FormElementError text={formErrors['post_welcome_message.attachments'][0] || ''} />
      ) : null}

      {postWelcomeThumbnails.length > 0 ? (
        <div ref={postWelcomeThumbnailsRef} className="thumbnails d-flex flex-column gap-2 mt-3">
          {postWelcomeThumbnails.map((file, idx) => (
            <UploadedFilePreview key={idx} file={file} onDelete={() => deleteFileHandler(idx)} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
