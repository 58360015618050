import type { InferOutput } from 'valibot';

import { pipe, object, transform, optional, string, nonEmpty, url, email, any, trim, union, literal } from 'valibot';

// const stringNonEmptySchema = (msg: string) => pipe(string(), nonEmpty(msg));

export const VCardFormSchema = object({
  address_name: optional(string()),
  birthday: optional(string()),
  city: optional(string()),
  country: optional(string()),
  download_url: optional(pipe(string(), url())),
  email: union([literal(''), pipe(string(), trim(), email())]),
  facebook_url: union([literal(''), pipe(string(), url())]),
  first_name: optional(pipe(string(), nonEmpty('Please enter your first name.'))),
  instagram_url: union([literal(''), pipe(string(), url())]),
  is_organization: pipe(
    any(),
    transform((input) => input === 'on')
  ),
  last_name: optional(pipe(string(), nonEmpty('Please enter your last name.'))),
  linkedin_url: union([literal(''), pipe(string(), url())]),
  middle_name: optional(string()),
  need_add_address: pipe(
    any(),
    transform((input) => input === 'on')
  ),
  note: optional(string()),
  note_footer: optional(string()),
  note_header: optional(string()),
  organization_name: optional(pipe(string(), nonEmpty("Please enter your organization's name."))),
  phone_number: optional(string()),
  photo_url: union([literal(''), pipe(string(), url())]),
  postal_code: optional(string()),
  state: optional(string()),
  street: optional(string()),
  support_url: union([literal(''), pipe(string(), url())]),
  twitter_url: union([literal(''), pipe(string(), url())]),
  website_url: union([literal(''), pipe(string(), url())]),
});
// forward(
//   partialCheck([['is_organization'], ['first_name']], (input) => {
//     return !input.is_organization
//       ? safeParse(stringNonEmptySchema('Please enter your first name.'), input.first_name).success
//       : true;
//   }),
//   ['first_name']
// )

export type IVCardForm = InferOutput<typeof VCardFormSchema>;
