import { API_ENDPOINT } from '../config/environment';
import { errorsHandler, handleUnathorizedRequest } from './utils';

export const dashboardAPIGetData = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/home/', {
    headers: {
      Authorization: `${token}`,
    },
  });
  return errorsHandler(response);
  /* if (response.status === 403) {
    handleUnathorizedRequest();
  }
  if (response.status !== 200 && response.status !== 403 && response.status !== 400) {
    return handleErrorStatusRequest(response.status);
  }
  if (response.status === 400) {
    const res = await response.json();
    return res;
  }
  const res = await response.json();
  return res; */
  //return catchTokenExpired(await response.json());
};

export const dashboardAPIGetCompaniesData = async (token: string) => {
  const response = await fetch(API_ENDPOINT + 'api/v1/dashboard/organizations/', {
    headers: {
      Authorization: `${token}`,
    },
  });
  if (response.status === 403) {
    handleUnathorizedRequest();
  }
  return response.json();
};

export const dashboardAPIPostEmailData = async (token: string, data: any) => {
  const response = await fetch(API_ENDPOINT + `api/v1/dashboard/home/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },

    body: JSON.stringify(data),
  });
  return errorsHandler(response);
};
