import { ICampaign } from '../../../types';

export function prepareCampaignData(data: ICampaign) {
  return {
    attachments: data.attachments,
    channel: data.channel,
    day_weeks: data.weekDays,
    effect: data.effect,
    from_time: data.fromTime,
    from_date: data.fromDate,
    list: data.list,
    name: data.name,
    organization_id: data.organizationId,
    status: data.status,
    subject: data.subject,
    text: data.text,
    timezone: data.timezone,
    to_time: data.toTime,
    utm_campaign: data.utmCampaign,
    utm_medium: data.utmMedium,
    utm_source: data.utmSource,
  };
}
