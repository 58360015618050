import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

import { IParticipant, TParticipantStatuses, TRoles } from '../../../types';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import {
  deleteParticipantFromOrganizationAPI,
  getOrganizationParticipantsAPI,
  updateParticipantAPI,
} from '../../../api';
import { Avatar, ConfirmModal, Select } from '../../../components/common';

const TeamOrganizationPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { organizationId } = useParams();
  const {
    state: { organizationName },
  } = useLocation();

  const [isEditable, setIsEditable] = useState<boolean>();
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState<string>();

  useEffect(() => {
    if (organizationId) {
      (async () => {
        try {
          if (!token) {
            console.error('You miss token');
            return;
          }
          const response = await getOrganizationParticipantsAPI(token, organizationId);

          if (response.code) {
            return;
          }

          setIsEditable(response.editable);
          setParticipants(response.participants);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [organizationId]);

  const applyStatusBadge = useMemo(
    () => (status: TParticipantStatuses) => {
      switch (status) {
        case 'active':
          return <span className="badge bg-light-success">{t(status)}</span>;
        case 'invited':
          return <span className="badge bg-light-warning">{t(status)}</span>;
        case 'suspended':
          return <span className="badge bg-light-danger">{t(status)}</span>;
        case 'blocked':
          return <span className="badge bg-light-danger">{t(status)}</span>;
        case 'unpaid':
          return <span className="badge bg-light-danger">{t(status)}</span>;
      }
    },
    [t]
  );

  const roleOptions = useMemo(
    () => [
      {
        id: 'admin',
        label: 'Admin',
        value: 'admin',
      },
      {
        id: 'manager',
        label: 'Manager',
        value: 'manager',
      },
    ],
    [t]
  );

  const openDeleteConfirmModal = (participantId: string) => {
    setSelectedParticipantId(participantId);
    setIsConfirmModalOpen(true);
  };

  const deleteParticipantFromOrganization = async (userId: string) => {
    try {
      if (!token || !organizationId) return;

      setIsDeleting(true);

      const response = await deleteParticipantFromOrganizationAPI(token, {
        organization_id: organizationId,
        user_id: userId,
      });

      if (response.code) {
        return;
      }

      setIsConfirmModalOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const changeRoleHandler = async (userId: string, role: TRoles) => {
    try {
      if (!token || !organizationId) return;

      await updateParticipantAPI(token, organizationId, { id: userId, role });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="page-wrapper">
      <div>
        <div className="page-header-title">
          <h2 className="h4 m-b-10">{organizationName}</h2>
        </div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/team">{t('team')}</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            {organizationName}
          </li>
        </ul>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('participants')}</h5>
          </div>
        </div>
        <div className="card-block">
          <div className="dt-responsive table-responsive mt-3">
            <table id="user-list-table" className="table nowrap">
              <thead>
                <tr>
                  <th>{t('member')}</th>
                  <th>{t('inviteDate')}</th>
                  <th>{t('status')}</th>
                  <th>{t('role')}</th>
                  <th className="text-right">{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {participants?.length > 0 &&
                  participants?.map((participant) => (
                    <tr key={participant.id}>
                      <td className="align-middle">
                        <div className="d-flex column-gap-2">
                          <Avatar initials={participant.first_name.charAt(0) + participant.last_name.charAt(0)} />
                          <div className="d-inline-block">
                            <h6 className="m-b-0">{`${participant.first_name} ${participant.last_name}`}</h6>
                            <p className="m-b-0">{participant.email}</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        {participant.added_date
                          ? new Intl.DateTimeFormat().format(new Date(participant.added_date))
                          : null}
                      </td>
                      <td className="align-middle">{applyStatusBadge(participant.status)}</td>
                      <td className="align-middle">
                        {roleOptions.length > 0 && participant.role !== 'owner' ? (
                          <Select
                            options={roleOptions}
                            initialValue={roleOptions.find((option) => option.value === participant.role)}
                            onChange={(option) => changeRoleHandler(participant.id, option.value as TRoles)}
                            disabled={!isEditable}
                          />
                        ) : null}
                      </td>
                      <td className="align-middle">
                        <div className="d-flex column-gap-2 align-items-center justify-content-end">
                          {isEditable ? (
                            <button
                              type="button"
                              className="avtar avtar-xs btn-light-danger border-0"
                              onClick={() => openDeleteConfirmModal(participant.id)}
                            >
                              <FeatherIcon icon="trash-2" size={20} />
                            </button>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isConfirmModalOpen ? (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('confirmAction')}
          text={t('confirmDeletion')}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={() => deleteParticipantFromOrganization(selectedParticipantId!)}
          confirmText={isDeleting ? 'Deleting...' : 'Yes, delete it'}
          dangerConfirmButton
        />
      ) : null}
    </div>
  );
};

export default TeamOrganizationPage;
