/* eslint-disable */
// @ts-nocheck
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const user = JSON.parse(localStorage.getItem('user'));

export const MetaPixel: FC = () => {
  const location = useLocation();

  useEffect(() => {
    // const user = localStorage.get('authUser')

    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    if (user) {
      fbq('init', process.env.REACT_APP_META_PIXEL_ID, {
        em: user.email,
        fn: user.firstName,
        ln: user.lastName,
        ph: user.phone,
        // zp: user.zipCode,
        country: user.country,
      });
    } else {
      fbq('init', process.env.REACT_APP_META_PIXEL_ID);
    }
  }, []);

  useEffect(() => {
    fbq('track', 'PageView');
  }, [location]);

  return null;
};
