/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../index.css';

type CountrySelectProps = {
  onCountry: (country: string) => void;
  onBlur: () => void;
  data: any;
};
const CountrySelect: React.FC<CountrySelectProps> = ({ onCountry, onBlur, data }) => {
  const { t } = useTranslation();

  return (
    <div className="form-group mb-3 t-a-l">
      <label htmlFor="country" className="m-b-5">
        {t('invitePage.table.country')}
      </label>
      <select
        onBlur={onBlur}
        onChange={(e) => onCountry?.(e.target.value)}
        id="country"
        name="country"
        className="form-control"
      >
        {data.map((c: any, index: number) => {
          return (
            <option key={index} defaultValue={data[0].name}>
              {c.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default CountrySelect;
