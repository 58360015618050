import { FC } from 'react';
import FeatherIcon from 'feather-icons-react';

interface IProps {
  file: any;
  onDelete: () => void;
}
export const UploadedFilePreview: FC<IProps> = (props) => {
  const { file, onDelete } = props;

  return (
    <div className="d-flex gap-3">
      {file.type === 'image' ? (
        <img className="wid-100" src={file.blobUrl ?? file.dataUrl} />
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-secondary-subtle wid-100 hei-80">
          <span className="h3 mb-0 text-uppercase">{file.name.split('.').at(-1)}</span>
        </div>
      )}
      <div className="d-flex flex-column justify-content-between w-100 overflow-hidden">
        <span className="text-truncate" title={file.name}>
          {file.name}
        </span>
        <div className="text-right p-2">
          <button type="button" className="btn btn-icon btn-light-danger" onClick={onDelete}>
            <FeatherIcon icon="trash" size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};
