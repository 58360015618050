/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { authAPIPostAddPassword } from '../../api';
import AuthLayout from '../../components/layout/AuthLayout';
import RegistrationAlert from '../../components/errors/RegistrationAlert';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import {LangDropdown} from '../../components/common';

const AddPassword: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { token } = useAuthContext();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [hide_1, setHide_1] = useState(true);
  const [hide_2, setHide_2] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const handlePassword = useCallback(
    (event: any) => {
      const value = event.target.value;
      setPassword(value.trim());
      if (value != confirmPassword) {
        setValidConfirmPassword(false);
      }
      if (value === confirmPassword) {
        setValidConfirmPassword(true);
      } else if (value === '') {
        setHide_1(true);
        setPassword(value.trim());
      } else {
        setPassword(value.trim());
        setValidConfirmPassword(true);
      }
    },
    [confirmPassword]
  );

  const handleConfirmPassword = useCallback(
    (event: any) => {
      setHide_2(false);
      const value = event.target.value;
      if (value === password) {
        setValidConfirmPassword(true);
        setConfirmPassword(value.trim());
      } else if (value === '') {
        setHide_2(true);
        setConfirmPassword(value.trim());
      } else {
        setValidConfirmPassword(false);
      }
    },
    [password]
  );

  const onInput = useCallback(
    (e: SyntheticEvent) => {
      (async () => {
        if (!loading) {
          try {
            e.preventDefault();
            setLoading(true);
            if (password === confirmPassword && token) {
              const data = {
                password: password,
                confirm_password: confirmPassword,
              };
              const res = await authAPIPostAddPassword(token, data);
              if (res.success) {
                navigate('/dashboard');
              }
            } else {
              console.log('Validation failed');
            }
          } catch (e) {
            setError(e as Error);
            console.log(error);
          } finally {
            setLoading(false);
          }
        }
      })();
    },
    [password, confirmPassword, error, token, navigate, loading]
  );

  return (
    <AuthLayout>
      <h4 className="text-center f-w-500 m-t-25">{t('forms.addPassword.title')}</h4>
      <p className="m-b-5 text-center">{t('forms.addPassword.description')}</p>
      <form onSubmit={onInput} className="form-group mb-3 t-a-l">
        <label htmlFor="password" className="m-b-5">
          {t('password')}
        </label>
        <input
          type="password"
          className={`form-control ${!hide_1 && 'invalid'} `}
          id="password"
          onChange={handlePassword}
          required
        />
        {!hide_1 ? <RegistrationAlert text={t('forms.signUp.errors.1086')} /> : null}
        <label htmlFor="confirmPassword" className="m-b-5 m-t-10">
          {t('forms.addPassword.confirmPassword')}
        </label>
        <input
          type="password"
          className={`form-control ${!hide_2 && 'invalid'} `}
          id="confirmPassword"
          onChange={handleConfirmPassword}
          required
        />
        {!validConfirmPassword && !hide_2 ? <RegistrationAlert text={t('forms.signUp.errors.1085')} /> : null}
        <div className="text-center m-t-25 m-b-15">
          <button type="submit" className="btn btn-primary shadow px-sm-4" disabled={!validConfirmPassword || loading}>
            {t('send')}
          </button>
        </div>
      </form>
      <LangDropdown />
    </AuthLayout>
  );
};
export default AddPassword;
