import { FC } from 'react';

interface IProps {
  text: string;
}

export const FormElementError: FC<IProps> = (props) => {
  const { text } = props;

  return <div className="invalid-feedback">{text}</div>;
};
